/*
  Theme Name: Sinace - Finance Consulting React Template
  Theme URL: https://mediacity.co.in/sinace
  Author: Media City
  Author URI: https://mediacity.co.in/
  Creation Date: 13 May 2023
  Description: A default stylesheet for Sinace - Finance Consulting React Template.
  Version: 1.0
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Common
# Custom Cursor
# Navigations One
# Mobile Nav
# Home Showcase
# Navigations Two
# Navigations Three
# Search Popup
# Main Slider
# Main Slider Two
# Main Slider Three
# About One
# Services One
# Grow Business
# Video One
# Testimonial One
# Project One
# Team One
# Counter One
# Brand One
# Contact One
# News One
# Site Footer
# Feature One
# About Two
# Services Two
# FAQ One
# Testimonial Two
# Project Two
# Brand Two
# Get Free
# Google Map
# Feature Two
# About Three
# CTA One
# Services Three
# Testimonial Three
# Project Three
# Together One
# Expectation One
# News Two
# Page Header
# News Page
# Contact Page
# Google Map Two
# News Details
# Sidebar
# Project Details
# Similar Project
# Services Details
# Team Details
# Contact Two
# Careers Page
# Feature Three
# Faq Search
# Faq Page
# Team Page
# Testimonial Page
# Services Page
# Portfolio Page
# About Four
# About Page Team
# About Page Testimonial
# Contact seven
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
  --sinace-font: 'Lexend', sans-serif;
  --sinace-gray: #6e7279;
  --sinace-gray-rgb: 110, 114, 121;
  --sinace-white: #ffffff;
  --sinace-white-rgb: 255, 255, 255;
  --sinace-base: #ff8300;
  --sinace-base-rgb: 255, 131, 0;
  --sinace-black: #0f213c;
  --sinace-black-rgb: 15, 33, 60;
  --sinace-primary: #edf2f8;
  --sinace-primary-rgb: 237, 242, 248;
  --sinace-bdr-color: #d9e1e9;
  --sinace-bdr-color-rgb: 217, 225, 233;
  --sinace-letter-spacing: -0.04em
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

body {
  font-family: var(--sinace-font);
  color: var(--sinace-gray);
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--sinace-gray);
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--sinace-font);
  color: var(--sinace-black);
  margin: 0;
  letter-spacing: var(--sinace-letter-spacing);
}

p {
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: var(--sinace-border);
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  opacity: 0.4;
  z-index: -1;
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
.custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--sinace-base);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  -webkit-transform: translate(calc(-50% + 5px), -50%);
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}

.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--sinace-base);
  opacity: .3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: width .3s, height .3s, opacity .3s;
  transition: width .3s, height .3s, opacity .3s;
  z-index: 999991;
}

.custom-cursor__hover {
  background-color: var(--sinace-base);
  opacity: 0.4;
}

.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: .4;
}


.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none !important;
  background-color: var(--sinace-base);
  color: var(--sinace-white);
  font-size: 14px;
  font-weight: 700;
  padding: 14px 50px 14px;
  overflow: hidden;
  transition: all 0.5s linear;
  z-index: 1;
}

.thm-btn::before {
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 260%;
  content: "";
  top: 126%;
  left: 50%;
  background-color: var(--sinace-black);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%) rotate(-8deg);
  border-radius: 80%;
  z-index: -1;
}

.thm-btn:hover:before {
  top: -40%;
}

.thm-btn:hover {
  color: var(--sinace-white);
}

.section-title {
  position: relative;
  display: block;
  margin-top: -12px;
  margin-bottom: 51px;
}

.section-title2 {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 15px;
}

.section-title__tagline-box {
  position: relative;
  display: inline-block;
}

.section-title__tagline {
  position: relative;
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  color: var(--sinace-gray);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.section-title__title {
  margin: 0;
  color: var(--sinace-black);
  font-size: 46px;
  line-height: 55px;
  font-weight: 600;
  margin-top: 2px;
  letter-spacing: -0.04em;
}

.section-title__title2 {
  margin: 0;
  color: var(--sinace-black);
  font-size: 26px;
  line-height: 40px;
  font-weight: 600;
  margin-top: 2px;
  letter-spacing: -0.04em;
}

.section-title__title2 span {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.section-title__title2 span::before {
  content: "";
  position: absolute;
  bottom: 9px;
  left: 4px;
  right: 2px;
  height: 5px;
  background-color: var(--sinace-base);
  border-radius: 5px;
  z-index: -1;
}

.section-title__title span {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.section-title__title span::before {
  content: "";
  position: absolute;
  bottom: 9px;
  left: 4px;
  right: 2px;
  height: 5px;
  background-color: var(--sinace-base);
  border-radius: 5px;
  z-index: -1;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select>.dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 0;
}

.bootstrap-select .dropdown-menu>li+li>a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu>li.selected>a {
  background: var(--sinace-black);
  color: #fff;
}

.bootstrap-select .dropdown-menu>li>a {
  font-size: 16px;
  font-weight: 500;
  padding: 4px 20px;
  color: #ffffff;
  background: var(--sinace-base);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
  background: var(--sinace-black);
  color: var(--sinace-white);
  cursor: pointer;
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-image: url(../images/loader.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60px auto;
  width: 100%;
  height: 100%;
}

/* scroll to top */
.scroll-to-top {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: var(--sinace-base);
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
}

.scroll-to-top i {
  color: var(--sinace-white);
  font-size: 18px;
  line-height: 50px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
  display: inline-block;
  transform: rotate(-90deg);
}

.scroll-to-top:hover {
  background-color: var(--sinace-black);
}

.scroll-to-top:hover i {
  color: #fff;
}


/*--------------------------------------------------------------
# Navigations One
--------------------------------------------------------------*/
.main-header {
  position: relative;
  display: block;
  background-color: var(--sinace-primary);
  width: 100%;
  transition: all 500ms ease;
  z-index: 999;
}

.main-menu {
  position: relative;
  display: block;
}

.main-menu__wrapper {
  position: relative;
  display: block;
}

.main-menu__wrapper-inner {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.main-menu__wrapper-inner-content {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu__top {
  position: relative;
  display: block;
  background-image: -moz-linear-gradient(90deg, rgba(254, 253, 253, 0) 0%, rgb(235, 240, 247) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(254, 253, 253, 0) 0%, rgb(235, 240, 247) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(254, 253, 253, 0) 0%, rgb(235, 240, 247) 100%);
  padding-left: 322px;
  padding-right: 322px;
  z-index: 1;
}

.main-menu__top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15.5px 0;
  z-index: 1;
}

.main-menu__logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60px;
  display: flex;
  align-items: center;
  z-index: 5;
}

.main-menu__logo a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-menu__call {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 60px;
  z-index: 2;
}

.main-menu__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: var(--sinace-primary);
  border-radius: 50%;
  font-size: 18px;
  color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__call-icon:hover {
  background-color: var(--sinace-base);
  color: var(--sinace-white);
}

.main-menu__call-content {
  margin-left: 10px;
}

.main-menu__call-sub-title {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

.main-menu__call-number {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 4px;
}

.main-menu__call-number a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__call-number a:hover {
  color: var(--sinace-base);
}

.main-menu__top-left {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.main-menu__contact-list {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu__contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu__contact-list li+li {
  margin-left: 30px;
}

.main-menu__contact-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  top: 2px;
}

.main-menu__contact-list li .icon i {
  font-size: 14px;
  color: var(--sinace-base);
}

.main-menu__contact-list li .text {
  margin-left: 10px;
}

.main-menu__contact-list li .text p {
  font-size: 13px;
  font-weight: 400;
  color: var(--sinace-gray);
}

.main-menu__contact-list li .text p a {
  color: var(--sinace-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__contact-list li .text p a:hover {
  color: var(--sinace-base);
}

.main-menu__top-right {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.main-menu__top-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu__top-menu li+li {
  margin-left: 35px;
}

.main-menu__top-menu li a {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: var(--sinace-gray);
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__top-menu li a:hover {
  color: var(--sinace-base);
}

.main-menu__top-menu li a:before {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: -17px;
  width: 1px;
  background-color: var(--sinace-gray);
  transform: rotate(10deg);
}

.main-menu__top-menu li:first-child a:before {
  display: none;
}

.main-menu__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
}

.main-menu__social a {
  position: relative;
  height: 26px;
  width: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--sinace-white);
  background-color: var(--sinace-black);
  font-size: 11px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.main-menu__social a:hover {
  color: var(--sinace-white);
  background-color: var(--sinace-base);
}

.main-menu__social a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--sinace-base);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.main-menu__social a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.main-menu__social a+a {
  margin-left: 10px;
}

.main-menu__bottom {
  display: block;
  padding-left: 322px;
  padding-right: 322px;
}

.main-menu__bottom-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--sinace-black);
  padding: 0 40px;
  padding-right: 10px;
}

.main-menu__main-menu-box {
  display: block;
}

.main-menu__right {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 11px 0;
}

.main-menu__search-box {
  position: relative;
  display: block;
  padding: 12px 0;
}

.main-menu__search-box:before {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: -20px;
  width: 1px;
  background-color: rgba(var(--sinace-white-rgb), .10);
}

.main-menu__search {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu__search:hover {
  color: var(--sinace-base);
}

.main-menu__btn-box {
  position: relative;
  display: block;
  margin-left: 30px;
}

.main-menu__btn {
  padding: 9px 40px 9px;
}

.main-menu__btn:hover {
  color: var(--sinace-base);
}

.main-menu__btn:before {
  background-color: var(--sinace-white);
}

.main-menu__btn:hover:before {
  top: -50%;
}

.stricky-header .main-menu__top {
  display: none;
}

.stricky-header .main-menu__wrapper-inner {
  box-shadow: none;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}



@media (min-width: 1200px) {

  .main-menu .main-menu__list,
  .main-menu .main-menu__list>li>ul,
  .main-menu .main-menu__list>li>ul>li>ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list>li>ul,
  .stricky-header .main-menu__list>li>ul>li>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.main-menu .main-menu__list>li,
.stricky-header .main-menu__list>li {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.main-menu .main-menu__list>li+li,
.stricky-header .main-menu__list>li+li {
  margin-left: 51px;
}

.main-menu .main-menu__list>li>a,
.stricky-header .main-menu__list>li>a {
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #7e8a9b;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 300;

}

.main-menu .main-menu__list>li>a::after,
.stricky-header .main-menu__list>li>a::after {
  content: '\f107';
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-left: 5px;
  font-size: 14px;
}

.main-menu .main-menu__list>li>a:only-child::after,
.stricky-header .main-menu__list>li>a:only-child::after {
  content: '';
}

.main-menu .main-menu__list>li>a::before,
.stricky-header .main-menu__list>li>a::before {
  content: "";
  height: 3px;
  border-radius: 0px;
  background-color: var(--sinace-base);
  position: absolute;
  bottom: -20px;
  left: 0px;
  right: 0px;
  transition: transform 500ms ease;
  transform: scale(0, 1);
  transform-origin: left center;
  z-index: 1;
}

.main-menu .main-menu__list>li.current>a::before,
.main-menu .main-menu__list>li:hover>a::before,
.stricky-header .main-menu__list>li.current>a::before,
.stricky-header .main-menu__list>li:hover>a::before {
  transform: scale(1, 1);
  transform-origin: right center;
}

.main-menu .main-menu__list>li.current>a,
.main-menu .main-menu__list>li:hover>a,
.stricky-header .main-menu__list>li.current>a,
.stricky-header .main-menu__list>li:hover>a {
  color: var(--sinace-white);
  text-shadow: 0.05px 0 0 rgb(255 255 255 / 80%);
}

.main-menu .main-menu__list>li>ul,
.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 270px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
  z-index: 99;
  background-color: rgb(255, 255, 255);
  padding: 20px 25px;
}

.sub-menu {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.main-menu .main-menu__list>li>ul>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul>li>ul {
  display: none;
}

.main-menu .main-menu__list>li:hover>ul,
.main-menu .main-menu__list>li>ul>li:hover>ul,
.stricky-header .main-menu__list>li:hover>ul,
.stricky-header .main-menu__list>li>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.main-menu .main-menu__list>li>ul>li,
.main-menu .main-menu__list>li>ul>li>ul>li,
.stricky-header .main-menu__list>li>ul>li,
.stricky-header .main-menu__list>li>ul>li>ul>li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}

.main-menu .main-menu__list>li>ul>li+li,
.main-menu .main-menu__list>li>ul>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li+li,
.stricky-header .main-menu__list>li>ul>li>ul>li+li {
  border-top: none;
}

.main-menu .main-menu__list>li>ul>li>a,
.main-menu .main-menu__list>li>ul>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>a,
.stricky-header .main-menu__list>li>ul>li>ul>li>a {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: var(--sinace-gray);
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 0px 8px;
  -webkit-transition: 500ms;
  transition: 500ms;
  background-color: var(--sinace-white);
  letter-spacing: var(--sinace-letter-spacing-two);
  border-bottom: 1px solid var(--sinace-bdr-color);
}

.main-menu .main-menu__list>li>ul>li:last-child>a,
.main-menu .main-menu__list>li>ul>li>ul>li:last-child>a,
.stricky-header .main-menu__list>li>ul>li:last-child>a,
.stricky-header .main-menu__list>li>ul>li>ul>li:last-child>a {
  border-bottom: 0;
}

.main-menu .main-menu__list>li>ul>li:hover>a,
.main-menu .main-menu__list>li>ul>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li:hover>a,
.stricky-header .main-menu__list>li>ul>li>ul>li:hover>a {
  background-color: var(--sinace-primary);
  color: var(--sinace-black);
  padding-left: 15px;
}

.main-menu .main-menu__list>li>ul>li>a::before,
.main-menu .main-menu__list>li>ul>li>ul>li>a::before,
.stricky-header .main-menu__list>li>ul>li>a::before,
.stricky-header .main-menu__list>li>ul>li>ul>li>a::before {
  position: absolute;
  top: 50%;
  right: 20px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-size: 14px;
  color: var(--sinace-base);
  transform: translateY(-50%) scale(0);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .main-menu__list>li>ul>li:hover>a::before,
.main-menu .main-menu__list>li>ul>li>ul>li:hover>a::before,
.stricky-header .main-menu__list>li>ul>li:hover>a::before,
.stricky-header .main-menu__list>li>ul>li>ul>li:hover>a::before {
  transform: translateY(-50%) scale(1);
}

.main-menu .main-menu__list>li>ul>li>ul,
.stricky-header .main-menu__list>li>ul>li>ul {
  top: 0;
  left: 100%;
}

.main-menu .main-menu__list li ul li>ul.right-align,
.stricky-header .main-menu__list li ul li>ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}

.main-menu-three__main-menu-box .main-menu__list>.megamenu,
.main-menu-two__main-menu-box .main-menu__list>.megamenu,
.main-menu__wrapper .main-menu__list>.megamenu {
  position: static;
}

.main-menu-three__main-menu-box .main-menu__list>.megamenu>ul,
.main-menu-two__main-menu-box .main-menu__list>.megamenu>ul,
.main-menu__wrapper .main-menu__list>.megamenu>ul {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent;
}

.main-menu-three__main-menu-box .main-menu__list>.megamenu>ul>li,
.main-menu-two__main-menu-box .main-menu__list>.megamenu>ul>li,
.main-menu__wrapper .main-menu__list>.megamenu>ul>li {
  padding: 0 !important;
}

.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  -webkit-transform: translateY(-120%);
  transform: translateY(-120%);
  -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: visibility 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}

.stricky-header .main-menu__inner {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-right: 0;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.mobile-nav__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .mobile-nav__buttons {
    display: none;
  }
}

.mobile-nav__buttons a {
  font-size: 20px;
  color: var(--sinace-base);
  cursor: pointer;
}

.mobile-nav__buttons a+a {
  margin-left: 10px;
}

.mobile-nav__buttons a:hover {
  color: var(--sinace-base);
}

.main-menu .mobile-nav__toggler {
  font-size: 20px;
  color: var(--sinace-white);
  cursor: pointer;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.main-menu .mobile-nav__toggler:hover {
  color: var(--sinace-base);
}

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  visibility: visible;
  -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--sinace-black);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--sinace-white);
  cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list>li>ul,
.mobile-nav__content .main-menu__list>li>ul>li>ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 0.5em;
}

.mobile-nav__content .main-menu__list>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li:not(:last-child),
.mobile-nav__content .main-menu__list>li>ul>li>ul>li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list>li>a>.main-menu-border {
  display: none !important;
}

.mobile-nav__content .main-menu__list>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>a,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 30px;
  color: #ffffff;
  font-size: 14px;
  font-family: var(--sinace-font);
  font-weight: 500;
  height: 46px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__content .main-menu__list>li>a.expanded .mobile-nav__content .main-menu__list>li>ul>li>a.expanded .mobile-nav__content .main-menu__list>li>ul>li>ul>li>a.expanded {
  color: var(--sinace-base);
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--sinace-base);
}

.mobile-nav__content .main-menu__list>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button {
  width: 30px;
  height: 30px;
  background-color: var(--sinace-base);
  border: none;
  outline: none;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  padding: 0;
}

.mobile-nav__content .main-menu__list>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>a>button.expanded,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--sinace-base);
}

/* no menu after 2rd level dropdown */
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>a>button,
.mobile-nav__content .main-menu__list>li>ul>li>ul>li>ul {
  display: none !important;
}

.mobile-nav__content .main-menu__list li.cart-btn span {
  position: relative;
  top: auto;
  right: auto;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
  font-size: 16px;
}

.mobile-nav__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
  color: var(--sinace-text-dark);
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__social a {
  font-size: 16px;
  color: var(--sinace-white);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__social a+a {
  margin-left: 30px;
}

.mobile-nav__social a:hover {
  color: var(--sinace-base);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  color: var(--sinace-text-dark);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-nav__contact li+li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #ffffff;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--sinace-base);
}

.mobile-nav__contact li>i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--sinace-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  margin-right: 10px;
  color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
  display: none;
}



/*--------------------------------------------------------------
# Home Showcase
--------------------------------------------------------------*/
.home-showcase {
  margin-top: -23px;
  margin-bottom: -23px;
}

.home-showcase__inner {
  padding: 40px 40px 36px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.home-showcase .row {
  --bs-gutter-x: 42px;
  --bs-gutter-y: 20px;
}

.home-showcase__image {
  position: relative;
  background-color: var(--sinace-base);
  overflow: hidden;
}

.home-showcase__image>img {
  width: 100%;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.home-showcase__image:hover>img {
  opacity: 0.75;
}

.home-showcase__image:hover .home-showcase__buttons {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  visibility: visible;
}

.home-showcase__buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.home-showcase__buttons__item {
  padding: 13px 20px 9px;
  width: 150px;
  text-align: center;
}

.home-showcase__buttons__item+.home-showcase__buttons__item {
  margin-top: 10px;
}

.home-showcase__title {
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: var(--sinace-black);
  margin-top: 17px;
}

.mobile-nav__wrapper .home-showcase .row [class*=col-] {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.mobile-nav__wrapper .home-showcase__inner {
  padding: 15px 0px;
  background-color: rgba(0, 0, 0, 0);
}

.mobile-nav__wrapper .home-showcase__title {
  color: var(--sinace-white, #ffffff);
}

/*--------------------------------------------------------------
# Navigations Two
--------------------------------------------------------------*/
.main-header-two {
  position: relative;
  display: block;
  width: 100%;
  transition: all 500ms ease;
  z-index: 999;
}

.stricky-header .main-menu-two__top {
  display: none;
}

.main-menu-two__wrapper {
  position: relative;
  display: block;
}

.main-menu-two__wrapper-inner {
  position: relative;
  display: block;
  background-color: var(--sinace-black);
  z-index: 1;
}

.main-menu-two__wrapper-inner-content {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu-two__top {
  position: relative;
  display: block;
  padding-left: 300px;
  background-color: var(--sinace-black);
  z-index: 1;
}

.main-menu-two__top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #ebf1f7;
  padding: 10.5px 60px;
  z-index: 1;
}

.main-menu-two__logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60px;
  display: flex;
  align-items: center;
  z-index: 5;
}

.main-menu-two__logo a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-menu-two__top-left {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.main-menu-two__contact-list {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-two__contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-two__contact-list li+li {
  margin-left: 30px;
}

.main-menu-two__contact-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  top: 2px;
}

.main-menu-two__contact-list li .icon i {
  font-size: 14px;
  color: var(--sinace-base);
}

.main-menu-two__contact-list li .text {
  margin-left: 10px;
}

.main-menu-two__contact-list li .text p {
  font-size: 13px;
  font-weight: 400;
  color: var(--sinace-gray);
}

.main-menu-two__contact-list li .text p a {
  color: var(--sinace-gray);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__contact-list li .text p a:hover {
  color: var(--sinace-base);
}

.main-menu-two__top-right {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.main-menu-two__top-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-two__top-menu li+li {
  margin-left: 35px;
}

.main-menu-two__top-menu li a {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: var(--sinace-gray);
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__top-menu li a:hover {
  color: var(--sinace-base);
}

.main-menu-two__top-menu li a:before {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: -17px;
  width: 1px;
  background-color: var(--sinace-gray);
  transform: rotate(10deg);
}

.main-menu-two__top-menu li:first-child a:before {
  display: none;
}

.main-menu-two__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
}

.main-menu-two__social a {
  position: relative;
  height: 26px;
  width: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--sinace-black);
  background-color: var(--sinace-white);
  font-size: 11px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.main-menu-two__social a:hover {
  color: var(--sinace-white);
  background-color: var(--sinace-base);
}

.main-menu-two__social a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--sinace-base);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.main-menu-two__social a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.main-menu-two__social a+a {
  margin-left: 10px;
}

.main-menu-two__bottom {
  position: relative;
  display: block;
  padding-left: 300px;
  padding-right: 60px;
  z-index: 1;
}

.main-menu-two__bottom-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main-menu-two__main-menu-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 60px;
  padding-right: 80px;
  z-index: 1;
}

.main-menu-two__main-menu-box:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  right: auto;
  background-color: var(--sinace-white);
  z-index: -1;
}

.main-menu-two__btn-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -165px;
}

.main-menu-two__btn {
  padding: 9px 40px 9px;
}

.main-menu-two__btn:hover {
  color: var(--sinace-base);
}

.main-menu-two__btn:before {
  background-color: var(--sinace-white);
}

.main-menu-two__btn:hover:before {
  top: -50%;
}

.main-menu-two__main-menu-box-left {
  position: relative;
  display: block;
}

.main-menu-two__main-menu-box-right {
  position: relative;
  display: block;
  margin-left: 200px;
}

.main-menu-two__search-box {
  position: relative;
  display: block;
  padding: 33px 0;
}

.main-menu-two__search-box:before {
  content: "";
  position: absolute;
  top: 25px;
  bottom: 25px;
  left: -20px;
  width: 1px;
  background-color: rgba(var(--sinace-white-rgb), .10);
}

.main-menu-two__search {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__search:hover {
  color: var(--sinace-base);
}

.main-menu-two__right {
  position: relative;
  display: block;
}

.main-menu-two__call {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
}

.main-menu-two__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: var(--sinace-primary);
  border-radius: 50%;
  font-size: 18px;
  color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__call-icon:hover {
  background-color: var(--sinace-base);
  color: var(--sinace-white);
}

.main-menu-two__call-content {
  margin-left: 10px;
}

.main-menu-two__call-sub-title {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

.main-menu-two__call-number {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 4px;
}

.main-menu-two__call-number a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-two__call-number a:hover {
  color: var(--sinace-base);
}

.main-menu-two .main-menu__list>li,
.stricky-header.main-menu-two .main-menu__list>li {
  padding-top: 30px;
  padding-bottom: 30px;
}

.main-menu-two .main-menu__list>li>a::before,
.stricky-header.main-menu-two .main-menu__list>li>a::before {
  bottom: -30px;
}

.main-menu-two .main-menu__list>li.current>a,
.main-menu-two .main-menu__list>li:hover>a,
.stricky-header.main-menu-two .main-menu__list>li.current>a,
.stricky-header.main-menu-two .main-menu__list>li:hover>a {
  color: var(--sinace-white);
  text-shadow: 0.05px 0 0 rgb(255 255 255 / 80%);
}


/*--------------------------------------------------------------
# Navigations Three
--------------------------------------------------------------*/
.main-header-three {
  position: relative;
  display: block;
  z-index: 999;
}

.main-menu-three__wrapper {
  position: relative;
  display: block;
}

.main-menu-three__wrapper-inner {
  position: relative;
  display: block;
  background-color: var(--sinace-white);
  z-index: 1;
}

.main-menu-three__wrapper-inner-content {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu-three__top {
  position: relative;
  display: block;
  padding-left: 305px;
  z-index: 1;
}

.main-menu-three__top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--sinace-black);
  padding: 10.5px 56px;
  padding-right: 80px;
  z-index: 1;
}

.main-menu-three__logo {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 80px;
  display: flex;
  align-items: center;
  z-index: 5;
}

.main-menu-three__logo a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-menu-three__top-left {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.main-menu-three__contact-list {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-three__contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-three__contact-list li+li {
  margin-left: 30px;
}

.main-menu-three__contact-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  top: 2px;
}

.main-menu-three__contact-list li .icon i {
  font-size: 14px;
  color: var(--sinace-base);
}

.main-menu-three__contact-list li .text {
  margin-left: 10px;
}

.main-menu-three__contact-list li .text p {
  font-size: 13px;
  font-weight: 400;
  color: #7e8a9b;
}

.main-menu-three__contact-list li .text p a {
  color: #7e8a9b;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__contact-list li .text p a:hover {
  color: var(--sinace-base);
}

.main-menu-three__top-right {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.main-menu-three__top-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-three__top-menu li+li {
  margin-left: 35px;
}

.main-menu-three__top-menu li a {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: #7e8a9b;
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__top-menu li a:hover {
  color: var(--sinace-base);
}

.main-menu-three__top-menu li a:before {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: -17px;
  width: 1px;
  background-color: #7e8a9b;
  transform: rotate(10deg);
}

.main-menu-three__top-menu li:first-child a:before {
  display: none;
}

.main-menu-three__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
}

.main-menu-three__social a {
  position: relative;
  height: 26px;
  width: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--sinace-black);
  background-color: var(--sinace-white);
  font-size: 11px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.main-menu-three__social a:hover {
  color: var(--sinace-white);
  background-color: var(--sinace-base);
}

.main-menu-three__social a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--sinace-base);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.main-menu-three__social a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.main-menu-three__social a+a {
  margin-left: 10px;
}

.main-menu-three__bottom {
  position: relative;
  display: block;
  padding-left: 305px;
  padding-right: 0;
  z-index: 1;
}

.main-menu-three__bottom-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.main-menu-three__main-menu-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 53px;
  padding-right: 50px;
  border-left: 3px solid var(--sinace-black);
  background-image: -moz-linear-gradient(90deg, rgb(235, 241, 247) 0%, rgba(254, 253, 253, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(235, 241, 247) 0%, rgba(254, 253, 253, 0) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(235, 241, 247) 0%, rgba(254, 253, 253, 0) 100%);
  z-index: 1;
}

.main-menu-three__main-menu-box-left {
  position: relative;
  display: block;
}

.main-menu-three__main-menu-box-right {
  position: relative;
  display: block;
  margin-left: 220px;
}

.main-menu-three__search-box {
  position: relative;
  display: block;
  padding: 33px 0;
}

.main-menu-three__search-box:before {
  content: "";
  position: absolute;
  top: 25px;
  bottom: 25px;
  left: -20px;
  width: 1px;
  background-color: #d9e1e9;
}

.main-menu-three__search {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__search:hover {
  color: var(--sinace-base);
}

.main-menu-three__right {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 80px;
}

.main-menu-three__btn-box {
  position: relative;
  display: block;
}

.main-menu-three__btn {
  padding: 9px 40px 9px;
}

.main-menu-three__btn:hover:before {
  top: -50%;
}

.main-menu-three__call {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  z-index: 2;
}

.main-menu-three__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: var(--sinace-primary);
  border-radius: 50%;
  font-size: 18px;
  color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__call-icon:hover {
  background-color: var(--sinace-base);
  color: var(--sinace-white);
}

.main-menu-three__call-content {
  margin-left: 10px;
}

.main-menu-three__call-sub-title {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

.main-menu-three__call-number {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 4px;
}

.main-menu-three__call-number a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu-three__call-number a:hover {
  color: var(--sinace-base);
}

.main-menu-three .main-menu__list>li,
.stricky-header.main-menu-three .main-menu__list>li {
  padding-top: 30px;
  padding-bottom: 30px;
}

.main-menu-three .main-menu__list>li>a::before,
.stricky-header.main-menu-three .main-menu__list>li>a::before {
  bottom: -30px;
}

.main-menu-three .main-menu__list>li.current>a::before,
.main-menu-three .main-menu__list>li:hover>a::before,
.stricky-header.main-menu-three .main-menu__list>li.current>a::before,
.stricky-header.main-menu-three .main-menu__list>li:hover>a::before {
  transform: scale(1, 1);
  transform-origin: right center;
}

.main-menu-three .main-menu__list>li.current>a,
.main-menu-three .main-menu__list>li:hover>a,
.stricky-header.main-menu-three .main-menu__list>li.current>a,
.stricky-header.main-menu-three .main-menu__list>li:hover>a {
  color: var(--sinace-black);
  text-shadow: 0.05px 0 0 rgb(15 33 60 / 80%);
}

.stricky-header .main-menu-three__top {
  display: none;
}


/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, -webkit-transform 500ms ease;
}

.search-popup.active {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sinace-black);
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
}

.search-popup__content form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  border-radius: 0;
  overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--sinace-base);
  border: 0;
}

.search-popup__content .thm-btn i {
  height: auto;
  width: auto;
  background-color: transparent;
  border-radius: 50%;
  color: var(--sinace-white);
  font-size: 22px;
  line-height: inherit;
  text-align: center;
  top: 0;
  margin-right: 0;
  padding-left: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup__content .thm-btn:hover:before {
  width: 165%;
}

/*--------------------------------------------------------------
# Main Slider
--------------------------------------------------------------*/
.main-slider {
  position: relative;
  background-color: var(--sinace-black);
  z-index: 10;
}

.main-slider .item {
  background-color: var(--sinace-black);
  position: relative;
  padding-top: 196px;
  padding-bottom: 196px;
  z-index: 10;
}

.main-slider__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.main-slider__bg:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(15, 33, 60, .40);
  z-index: -1;
}

.main-slider__shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -moz-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  opacity: 0.902;
  z-index: 1;
}

.main-slider__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: .21;
  z-index: 3;
}

.main-slider__shape-1 img {
  width: auto !important;
}

.main-slider__shape-2 {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: lighten;
  z-index: 2;
}

.main-slider__shape-2 img {
  width: auto !important;
}

.main-slider__shape-3 {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: darken;
  z-index: 1;
}

.main-slider__shape-3 img {
  width: auto !important;
}

.main-slider__shape-4 {
  position: absolute;
  bottom: 0;
  left: 0;
  mix-blend-mode: darken;
  z-index: 1;
}

.main-slider__shape-4 img {
  width: auto !important;
}

.main-slider__shape-5 {
  position: absolute;
  bottom: 0;
  left: 185px;
  mix-blend-mode: lighten;
  z-index: 1;
}

.main-slider__shape-5 img {
  width: auto !important;
}

.main-slider__content {
  position: relative;
  display: block;
  z-index: 10;
}

.main-slider__sub-title {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--sinace-white);
  background-color: rgba(var(--sinace-white-rgb), .10);
  padding: 7px 29px 7px;
  border-radius: 23px;
  border: 1px solid rgba(var(--sinace-white-rgb), .10);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider__title {
  position: relative;
  font-size: 90px;
  color: var(--sinace-white);
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -0.04em;
  margin-top: 16px;
  margin-bottom: 38px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider__btn:hover {
  color: var(--sinace-base);
}

.main-slider__btn:before {
  background-color: var(--sinace-white);
}

.active .main-slider__title,
.active .main-slider__btn-box,
.active .main-slider__sub-title {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}


.main-slider .owl-theme .owl-nav {
  position: absolute;
  top: 41%;
  left: 50%;
  z-index: 100;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  height: 0;
  line-height: 0;
}

.main-slider .owl-theme .owl-nav [class*=owl-] {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgba(var(--sinace-black-rgb), 1);
  background-color: rgba(var(--sinace-white-rgb), .20);
  border: none;
  border-radius: 50%;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
  padding: 18px 0 !important;
}

.main-slider .owl-theme .owl-nav [class*=owl-]:hover {
  color: rgba(var(--sinace-black-rgb), 1);
  background-color: rgba(var(--sinace-white-rgb), 1);
}

.main-slider .owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
  margin-top: 10px;
}


/*--------------------------------------------------------------
# Main Slider Two
--------------------------------------------------------------*/
.main-slider-two {
  position: relative;
  z-index: 10;
}

.main-slider-two .item {
  position: relative;
  padding-top: 195px;
  padding-bottom: 200px;
  z-index: 10;
}

.main-slider-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.main-slider-two__bg:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(15, 33, 60, .40);
  z-index: -1;
}

.main-slider-two__bottom-shape {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: 100% 100%;
  z-index: 10;
}

.main-slider-two__shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-image: -moz-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  opacity: 0.902;
  width: 945px;
  z-index: 1;
}

.main-slider-two__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: color-burn;
  opacity: .45;
  z-index: 1;
}

.main-slider-two__shape-1 img {
  width: auto !important;
}

.main-slider-two__content {
  position: relative;
  display: block;
  z-index: 15;
}

.main-slider-two__title {
  position: relative;
  font-size: 80px;
  color: var(--sinace-white);
  font-weight: 700;
  line-height: 80px;
  letter-spacing: -0.04em;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two__title span {
  position: relative;
  display: inline-block;
}

.main-slider-two__title span:before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  height: 32px;
  background-image: url(../images/shapes/main-slider-three-line.png);
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.main-slider-two__text {
  font-size: 18px;
  color: var(--sinace-white);
  line-height: 36px;
  margin-top: 21px;
  margin-bottom: 28px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-two__btn:hover {
  color: var(--sinace-base);
}

.main-slider-two__btn:before {
  background-color: var(--sinace-white);
}

.main-slider-two__rounded-text {
  position: absolute;
  top: 72px;
  right: 40px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
  z-index: 1;
}

.main-slider-two__curved-circle-box {
  position: relative;
  display: block;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  z-index: 2;
}

.main-slider-two__curved-circle-box .curved-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--sinace-white);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: .4em;
}

.main-slider-two__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-slider-two__icon img {
  width: auto !important;
}

.active .main-slider-two__title,
.active .main-slider-two__btn-box,
.active .main-slider-two__text,
.active .main-slider-two__rounded-text {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}


.main-slider-two .owl-theme .owl-nav {
  position: absolute;
  top: 44%;
  left: 50%;
  z-index: 100;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  padding: 0 80px;
  margin: 0 auto;
  height: 0;
  line-height: 0;
}

.main-slider-two .owl-theme .owl-nav [class*=owl-] {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: rgba(var(--sinace-black-rgb), 1);
  background-color: rgba(var(--sinace-white-rgb), .20);
  border: none;
  border-radius: 50%;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
  padding: 18px 0 !important;
}

.main-slider-two .owl-theme .owl-nav [class*=owl-]:hover {
  color: rgba(var(--sinace-black-rgb), 1);
  background-color: rgba(var(--sinace-white-rgb), 1);
}

.main-slider-two .owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
  margin-top: 10px;
}

/*--------------------------------------------------------------
# Main Slider Three
--------------------------------------------------------------*/
.main-slider-three {
  position: relative;
  display: block;
  background-color: #ebf1f7;
  z-index: 10;
}

.main-slider-three .item {
  position: relative;
  padding-top: 195px;
  padding-bottom: 245px;
  background-color: #ebf1f7;
  z-index: 10;
}

.main-slider-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.main-slider-three__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: luminosity;
  opacity: .15;
  z-index: 1;
}

.main-slider-three__shape-1 img {
  width: auto !important;
}

.main-slider-three__content {
  position: relative;
  display: block;
  z-index: 15;
}

.main-slider-three__shape-2 {
  position: absolute;
  bottom: 20px;
  left: 260px;
  z-index: -1;
}

.main-slider-three__shape-2 img {
  width: auto !important;
}

.main-slider-three__title-box {
  position: relative;
  display: block;
  margin-bottom: 43px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-three__title {
  position: relative;
  font-size: 90px;
  color: var(--sinace-black);
  font-weight: 300;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
}

.main-slider-three__title span {
  font-weight: 700;
  color: var(--sinace-base);
}

.main-slider-three__text {
  font-size: 16px;
  color: var(--sinace-black);
  font-weight: 700;
  line-height: 26px;
  position: absolute;
  left: 308px;
  bottom: 10px;
}

.main-slider-three__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.main-slider-three__btn-two {
  color: var(--sinace-black);
  background-color: var(--sinace-white);
  margin-left: 10px;
}

.active .main-slider-three__title-box,
.active .main-slider-three__btn-box {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-three .owl-theme .owl-nav {
  width: 100%;
  max-width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
}

.main-slider-three .owl-theme .owl-nav [class*=owl-] {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: rgba(var(--sinace-black-rgb), 1);
  background-color: rgba(var(--sinace-white-rgb), 1);
  border: none;
  border-radius: 50%;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
  padding: 0px 0px !important;
}

.main-slider-three .owl-theme .owl-nav [class*=owl-]:hover {
  color: rgba(var(--sinace-white-rgb), 1);
  background-color: rgba(var(--sinace-base-rgb), 1);
}

.main-slider-three .owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
  margin-left: 0px;
}

.main-slider-three .owl-theme .owl-dots {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 1200px;
  width: 100%;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  padding: 0 15px;
  margin: 0 auto;
  height: 0;
  line-height: 0;
}

.main-slider-three .owl-theme .owl-dots .owl-dot+.owl-dot {
  margin-left: 10px;
}

.main-slider-three .owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(var(--sinace-white-rgb), .30);
  margin: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-slider-three .owl-theme .owl-dots .owl-dot:hover span,
.main-slider-three .owl-theme .owl-dots .owl-dot.active span {
  background-color: rgba(var(--sinace-white-rgb), 1);
}

/*--------------------------------------------------------------
# About One
--------------------------------------------------------------*/
.about-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.about-one__left {
  position: relative;
  display: block;
  margin-right: 170px;
}

.about-one__img-box {
  position: relative;
  display: block;
}

.about-one__img {
  position: relative;
  display: block;
}

.about-one__img img {
  width: 100%;
}

.about-one__img-2 {
  position: absolute;
  bottom: -81px;
  right: -200px;
}

.about-one__img-2 img {
  width: auto;
}

.about-one__shape-1 {
  position: absolute;
  bottom: 0;
  left: 30px;
}

.about-one__shape-1 img {
  width: auto;
}

.about-one__shape-2 {
  position: absolute;
  top: 38px;
  right: -108px;
}

.about-one__shape-2 img {
  width: auto;
}

.about-one__right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.about-one__right .section-title {
  margin-bottom: 30px;
}

.about-one__points-and-experience {
  position: relative;
  display: block;
  border: 1px solid var(--sinace-bdr-color);
  padding: 25px 14px 14px;
  margin-top: 45px;
  margin-bottom: 50px;
}

.about-one__points-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px 0;
  margin-bottom: 26px;
}

.about-one__points-list {
  position: relative;
  display: block;
}

.about-one__points-list+.about-one__points-list {
  margin-left: 50px;
}

.about-one__points-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.about-one__points-list li+li {
  margin-top: 8px;
}

.about-one__points-list li .icon {
  position: relative;
  display: inline-block;
}


.about-one__points-list li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 9px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  border-radius: 50%;
}

.about-one__points-list li .text {
  margin-left: 8px;
}

.about-one__points-list li .text p {
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: var(--sinace-black);
  letter-spacing: -0.04em;
}

.about-one__experience-box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #eef3f8;
  padding: 8px 17px 12px;
}

.about-one__experience-icon {
  position: relative;
  display: inline-block;
  top: 6px;
}

.about-one__experience-icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--sinace-base);
}

.about-one__experience-text {
  position: relative;
  margin-left: 20px;
}

.about-one__experience-text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.about-one__btn-box {
  position: relative;
  display: inline-block;
}

.about-one__shape-3 {
  position: absolute;
  right: -109px;
  top: -33px;
}

.about-one__shape-3 img {
  width: auto;
}

/*--------------------------------------------------------------
# Services One
--------------------------------------------------------------*/
.services-one {
  position: relative;
  display: block;
  background-color: #eef3f8;
  padding: 120px 0 200px;
  margin-bottom: 100px;
  z-index: 1;
}

.services-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 414px;
  opacity: .15;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.services-one__top {
  position: relative;
  display: block;
  margin-bottom: 51px;
}

.services-one__left {
  position: relative;
  display: block;
}

.services-one__left .section-title {
  margin-bottom: 0;
}

.services-one__right {
  position: relative;
  display: block;
}

.services-one__text {
  margin-top: 13px;
}

.services-one__bottom {
  position: relative;
  display: block;
}

.services-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.services-one__title-box {
  position: relative;
  display: block;
  background-color: var(--sinace-white);
  padding: 24px 30px 60px;
  z-index: 1;
}

.services-one__title-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sinace-black);
  background-position: center top;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}


.services-one__single:hover .services-one__title-box::before {
  transform: scaleY(1.0);
}

.services-one__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}

.services-one__title a {
  color: #222232;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-one__single:hover .services-one__title a {
  color: var(--sinace-white);
}

.services-one__img-box {
  position: relative;
  display: block;
}

.services-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.services-one__img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(var(--sinace-black-rgb), .30);
  width: 0%;
  transform: translateY(100%);
  transition: all 500ms ease;
  z-index: 1;
}

.services-one__single:hover .services-one__img:before {
  transform: translateY(0);
  width: 100%;
}

.services-one__img img {
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-one__single:hover .services-one__img img {
  transform: scale(1.05);
}

.services-one__icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 76px;
  top: -38px;
  right: 20px;
  border-radius: 50%;
  background-color: var(--sinace-base);
  z-index: 2;
}

.services-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--sinace-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.services-one__single:hover .services-one__icon span {
  transform: scale(0.9);
}

.services-one__read-more {
  position: relative;
  display: block;
}

.services-one__read-more a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  color: var(--sinace-white);
  padding: 11px 30px 12px;
  background-color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-one__read-more a:hover {
  background-color: var(--sinace-base);
}

.services-one__read-more a span {
  font-size: 16px;
}

/*.services-one__bottomtext {
  position: relative;
  display: block;
}*/

.services-one__bottomtext {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: var(--sinace-black);
  padding: 11px 30px 12px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-one__bottomtext-box {
  position: relative;
  display: block;
  background-color: var(--sinace-white);
  z-index: 1;
}

.services-one__bottomtext-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sinace-black);
  background-position: center top;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}


.services-one__single:hover .services-one__bottomtext-box::before {
  transform: scaleY(1.05);
}

.services-one__bottomtext-box p:hover {
  color: var(--sinace-white);
}

/*--------------------------------------------------------------
# Grow Business
--------------------------------------------------------------*/
.grow-business {
  position: relative;
  display: block;
  margin-top: -270px;
  z-index: 2;
}

.grow-business__inner {
  position: relative;
  display: block;
  background-color: var(--sinace-white);
  z-index: 1;
}

.grow-business__inner:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10000000px;
  right: 0;
  background-color: var(--sinace-white);
  z-index: -1;
}

.grow-business__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -375px;
  right: 627px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  z-index: -1;
  mix-blend-mode: luminosity;
  opacity: .15;
}

.grow-business__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  background-image: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  background-image: -ms-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  z-index: -1;
}

/* .grow-business__inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10000px;
  bottom: 0;
  right: 0;
  background-color: var(--sinace-white);
  z-index: -1;
} */

.grow-business__left {
  position: relative;
  display: block;
  margin-right: 70px;
  padding: 100px 0 100px;
  z-index: 1;
}

.grow-business__left .section-title {
  margin-bottom: 21px;
}

.grow-business__points {
  position: relative;
  display: block;
  margin-top: 22px;
  margin-bottom: 37px;
}

.grow-business__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.grow-business__points li+li {
  margin-top: 6px;
}

.grow-business__points li .icon {
  position: relative;
  display: inline-block;
}

.grow-business__points li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 9px;
  color: var(--sinace-white);
  border-radius: 50%;
  background-color: var(--sinace-base);
}

.grow-business__points li .text {
  margin-left: 15px;
}

.grow-business__points li .text p {
  font-weight: 500;
  color: var(--sinace-black);
}

.grow-business__progress {
  position: relative;
  display: block;
}

.grow-business__progress-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: var(--sinace-black);
  margin-bottom: 15px;
}

.grow-business__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: var(--sinace-primary);
  border-radius: 6px;
}

.grow-business__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 5px;
  background-color: var(--sinace-base);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.grow-business__progress .bar-inner::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -3px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: var(--sinace-black);
  border: 2px solid var(--sinace-white);
  transform: translateY(-50%);
}

.grow-business__progress .count-text {
  position: absolute;
  right: -6px;
  bottom: 26px;
  color: var(--sinace-gray);
  line-height: 26px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.grow-business__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.grow-business__progress .bar.marb-0 {
  margin-bottom: 0;
}

.grow-business__right {
  position: relative;
  display: block;
  background-color: var(--sinace-base);
  margin-left: 40px;
  padding: 100px 100px 91px;
  overflow: hidden;
  z-index: 1;
}

.grow-business__right-points {
  position: relative;
  display: block;
}

.grow-business__right-points li {
  position: relative;
  display: block;
}

.grow-business__right-points li+li {
  margin-top: 29px;
}

.grow-business__right-points-icon {
  position: relative;
  display: inline-block;
}

.grow-business__right-points-icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--sinace-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.grow-business__right-points li:hover .grow-business__right-points-icon span {
  transform: scale(0.9);
}

.grow-business__right-points-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--sinace-white);
  margin-top: 10px;
  margin-bottom: 16px;
}

.grow-business__right-points-text {
  color: rgba(var(--sinace-white-rgb), .70);
}

.grow-business__shape-1 {
  position: absolute;
  top: 0;
  right: -30px;
  mix-blend-mode: multiply;
  opacity: .15;
  z-index: -1;
}

.grow-business__shape-1 img {
  width: auto;
}
/*--------------------------------------------------------------
# Video Header
*/
.video-header {
  position: relative;
  display: block;
  z-index: 1;
}
.main-slider-two__icon {
  position: relative;
  /*top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;*/
  padding: 477px 0 120px;
  margin-top: -357px;
}

.video-header__inner {
  position: relative;
  display: block;
  text-align: center;
}

.video-header__video-link {
  position: relative;
  display: inline-block;
}

.video-header__video-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  line-height: 100px;
  text-align: center;
  font-size: 55px;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.video-header__video-icon img {
  width: auto;
}

.video-header__video-link .ripple,
.video-header__video-icon .ripple:before,
.video-header__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--sinace-white-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--sinace-white-rgb), 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--sinace-white-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--sinace-white-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.video-header__video-icon .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.video-header__video-icon .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}


/*--------------------------------------------------------------
# Video One
--------------------------------------------------------------*/
.video-one {
  position: relative;
  display: block;
  background-color: var(--sinace-black);
  padding: 477px 0 120px;
  margin-top: -357px;
  z-index: 1;
}

.video-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .40;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.video-one__inner {
  position: relative;
  display: block;
  text-align: center;
}

.video-one__video-link {
  position: relative;
  display: inline-block;
}

.video-one__video-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  line-height: 100px;
  text-align: center;
  font-size: 55px;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.video-one__video-icon img {
  width: auto;
}

.video-one__video-link .ripple,
.video-one__video-icon .ripple:before,
.video-one__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--sinace-white-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--sinace-white-rgb), 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--sinace-white-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--sinace-white-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.video-one__video-icon .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.video-one__video-icon .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.video-one__title {
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  color: var(--sinace-white);
  margin-top: 8px;
  margin-bottom: 36px;
}

.video-one__btn-box {
  position: relative;
  display: block;
}

.video-one__btn::before {
  background-color: var(--sinace-white);
}

.video-one__btn:hover {
  color: var(--sinace-black);
}

/*--------------------------------------------------------------
# Testimonial One
--------------------------------------------------------------*/
.testimonial-one {
  position: relative;
  display: block;
  background-color: #eef3f8;
  padding: 120px 0 99px;
  z-index: 1;
}

.testimonial-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .10;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.testimonial-one__left {
  position: relative;
  display: block;
  z-index: 5;
}

.testimonial-one__left .section-title {
  margin-bottom: 22px;
}

.testimonial-one__left-text {
  position: relative;
  display: block;
}

.testimonial-one__rounded-text {
  position: relative;
  display: block;
  margin-top: 35px;
  z-index: 1;
}

.testimonial-one__curved-circle-box {
  position: relative;
  display: block;
  height: 135px;
  width: 135px;
  border-radius: 50%;
  z-index: 2;
}

.testimonial-one__curved-circle-box .curved-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--sinace-black);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: .4em;
}

.testimonial-one__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-one__icon img {
  width: auto !important;
}

.testimonial-one__right {
  position: relative;
  display: block;
}

.testimonial-one__carousel {
  position: relative;
  display: block;
}

.testimonial-one__single {
  position: relative;
  display: block;
  text-align: center;
}

.testimonial-one__content {
  position: relative;
  display: block;
  text-align: center;
  padding: 0px 40px 22px;
  background-color: rgb(255, 255, 255);
  box-shadow: -10px 0px 60px 0px rgba(0, 0, 0, 0.07);
  z-index: 1;
}

.testimonial-one__content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background-color: var(--sinace-base);
  z-index: -1;
}

.testimonial-one__img {
  position: relative;
  display: block;
  width: 53px;
  border-radius: 50%;
  margin: 0 auto;
  z-index: 1;
}

.testimonial-one__img::before {
  content: "";
  position: absolute;
  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;
  background-color: var(--sinace-white);
  border: 2px solid var(--sinace-base);
  border-radius: 50%;
  z-index: -1;
}

.testimonial-one__img img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-one__ratting {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 27px;
  margin-bottom: 12px;
}

.testimonial-one__ratting span {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: var(--sinace-base);
}

.testimonial-one__ratting span+span {
  margin-left: 2px;
}

.testimonial-one__text {
  font-weight: 400;
}

.testimonial-one__client-info {
  position: relative;
  display: block;
  margin-top: 37px;
}

.testimonial-one__client-info h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.testimonial-one__client-info h3 a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-one__client-info h3 a:hover {
  color: var(--sinace-base);
}

.testimonial-one__client-info p {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  margin-top: 1px;
}

.testimonial-one__shape-1 {
  position: relative;
  top: 10px;
  left: 0;
  border-top: 40px solid var(--sinace-base);
  border-left: 135px solid transparent;
  border-right: 135px solid transparent;
  z-index: -1;
}

.testimonial-one__shape-2 {
  position: absolute;
  bottom: -20px;
  left: 0;
  border-top: 20px solid var(--sinace-white);
  border-left: 136px solid transparent;
  z-index: -1;
}

.testimonial-one__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-one__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}

.testimonial-one__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

/*--------------------------------------------------------------
# Project One
--------------------------------------------------------------*/
.project-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.project-one .container {
  max-width: 1800px;
}

.project-one__bottom {
  position: relative;
  display: block;
}

.project-one__carousel {
  position: relative;
  display: block;
}

.project-one__single {
  position: relative;
  display: block;
}

.project-one__img-box {
  position: relative;
  display: block;
}

.project-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.project-one__img:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--sinace-black-rgb), .60);
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
  z-index: 1;
}

.project-one__single:hover .project-one__img:before {
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.project-one__img img {
  width: 100%;
  transition: all 500ms ease;
  transform: scale(1);
}

.project-one__single:hover .project-one__img img {
  transform: scale(1.05);
}

.project-one__content {
  position: relative;
  display: block;
  background-color: var(--sinace-black);
  padding: 33px 50px 47px;
  margin-top: -110px;
  margin-right: 260px;
  background-position: center bottom;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: 2;
}

.project-one__single:hover .project-one__content {
  transform: scaleY(1.0);
}

.project-one__content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--sinace-base);
}

.project-one__content h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 1px;
}

.project-one__content h3 a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-one__content h3 a:hover {
  color: var(--sinace-base);
}

.project-one__arrow {
  position: absolute;
  top: 0;
  right: -50px;
}

.project-one__arrow a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 16px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-one__single:hover .project-one__arrow a {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition-delay: 500ms;
  opacity: 1;
}

.project-one__arrow a:hover {
  color: var(--sinace-black);
  background-color: var(--sinace-white);
}

.project-one__carousel.owl-theme .owl-nav {
  position: relative;
  display: block;
  margin: 30px 0 0;
}

.project-one__carousel.owl-theme .owl-nav .owl-next {
  height: 52px;
  width: 52px;
  line-height: 52px;
  border-radius: 50%;
  color: var(--sinace-black);
  background-color: var(--sinace-primary);
  font-size: 16px;
  margin: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-one__carousel.owl-theme .owl-nav .owl-prev {
  height: 52px;
  width: 52px;
  line-height: 52px;
  border-radius: 50%;
  color: var(--sinace-black);
  background-color: var(--sinace-primary);
  font-size: 16px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
  display: inline-block;
}

.project-one__carousel.owl-theme .owl-nav .owl-next {
  margin-left: 5px;
}

.project-one__carousel.owl-theme .owl-nav .owl-prev {
  margin-right: 5px;
}

.project-one__carousel.owl-theme .owl-nav .owl-next span,
.project-one__carousel.owl-theme .owl-nav .owl-prev span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-one__carousel.owl-theme .owl-nav .owl-next:hover,
.project-one__carousel.owl-theme .owl-nav .owl-prev:hover {
  background-color: var(--sinace-base);
  color: var(--sinace-white);
}

/*--------------------------------------------------------------
# Team One
--------------------------------------------------------------*/
.team-one {
  position: relative;
  display: block;
  padding-bottom: 90px;
  z-index: 1;
}

.team-one__top {
  position: relative;
  display: block;
  margin-bottom: 51px;
}

.team-one__top .section-title {
  margin-bottom: 0;
}

.team-one__left {
  position: relative;
  display: block;
}

.team-one__right {
  position: relative;
  display: block;
  margin-top: 18px;
}

.team-one__bottom {
  position: relative;
  display: block;
}

.team-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.team-one__img-box {
  position: relative;
  display: block;
}

.team-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.team-one__img:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--sinace-black-rgb), .40);
  visibility: hidden;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateZ(100px);
  transform: scaleY(0) translateZ(100px);
  -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease, -webkit-transform 700ms ease;
  z-index: 1;
}

.team-one__single:hover .team-one__img:before {
  visibility: visible;
  -webkit-transform: scaleY(1) translateZ(0px);
  transform: scaleY(1) translateZ(0px);
}

.team-one__img img {
  width: 100%;
  transition: all 500ms ease;
  transform: scale(1);
}

.team-one__single:hover .team-one__img img {
  transform: scale(1.05);
}

.team-one__content {
  position: relative;
  display: block;
  margin-top: -69px;
  margin-right: 40px;
  padding: 25px 40px 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  background-position: center top;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: scaleY(1);
  z-index: 2;
}

.team-one__single:hover .team-one__content {
  transform: scaleY(0.0);
}

.team-one__title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

.team-one__title a {
  color: var(--sinace-black);
}

.team-one__sub-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: var(--sinace-base);
  text-transform: uppercase;
  margin-top: 1px;
  padding-bottom: 12px;
  border-bottom: 2px solid #eef3f8;
}

.team-one__arrow-box {
  position: absolute;
  bottom: 32px;
  right: 40px;
}

.team-one__arrow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 34px;
  font-size: 13px;
  color: var(--sinace-black);
  background-color: #eef3f8;
}

.team-one__hover-content {
  position: absolute;
  left: 0;
  right: 40px;
  bottom: -55px;
  padding: 25px 40px 30px;
  background-color: rgb(15, 33, 60);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  background-position: center bottom;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: 2;
}

.team-one__single:hover .team-one__hover-content {
  transform: scaleY(1.0);
}

.team-one__hover-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

.team-one__hover-title a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__hover-title a:hover {
  color: var(--sinace-base);
}

.team-one__hover-sub-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: var(--sinace-base);
  text-transform: uppercase;
  margin-top: 1px;
}

.team-one__hover-arrow-box {
  position: absolute;
  bottom: 32px;
  right: 40px;
}

.team-one__hover-arrow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 34px;
  font-size: 13px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__hover-arrow:hover {
  color: var(--sinace-black);
}

.team-one__hover-text {
  font-size: 15px;
  font-weight: 300;
  color: #7e8a9b;
  margin-top: 13px;
  padding-bottom: 50px;
  border-bottom: 2px solid var(--sinace-base);
}

.team-one__social {
  position: absolute;
  right: 0;
  bottom: 50px;
  background-color: var(--sinace-white);
  padding: 11px 12px 10px;
  transform: scaleY(0.0);
  transform-origin: center;
  transform-style: preserve-3d;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  transform-origin: right center;
}

.team-one__social::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  border-top: 6px solid var(--sinace-white);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  transform: translateX(-50%);
}


.team-one__single:hover .team-one__social {
  transform: scaleY(1.0);
  transform-origin: bottom center;
  transition-delay: 500ms;
}

.team-one__social li {
  position: relative;
  display: block;
  line-height: 29px;
}

.team-one__social li a {
  position: relative;
  display: block;
  font-size: 13px;
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__social li a:hover {
  color: var(--sinace-base);
}
/*--------------------------------------------------------------
# Motto
--------------------------------------------------------------*/
.motto {
  position: relative;
  display: block;
  padding-bottom: 400px;
}

.motto__inner {
  position: relative;
  display: block;
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--sinace-primary);
  padding: 50px 0 50px;
  z-index: 1;
}

.motto__right {
  position: relative;
  display: block;
  z-index: 5;
}

.motto__count-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.motto__count-box li {
  position: relative;
  text-align: center;
  flex: 0 0 25%;
  max-width: 25%;
  width: 100%;
  margin-bottom: 21px;
}

.motto-one__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 116px;
  height: 116px;
  border-radius: 50%;
  background-color: var(--sinace-white);
  margin: 0 auto;
  z-index: 1;
}

.motto-one__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 50%;
  background-color: var(--sinace-black);
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.motto-one__count-box li:hover .motto-one__icon:before {
  transform: scaleX(1);
}

.motto-one__count-box li:hover .motto-one__icon span {
  transform: scale(0.9);
  color: var(--sinace-white);
  background-color: var(--sinace-black);
}

.motto-one__icon span {
  position: relative;
  display: inline-block;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.motto-one__text{
font-weight: 600;
}

.motto-one__shadow {
  content: "";
  position: absolute;
  top: 0;
  left: 186px;
  bottom: 0;
  width: 459px;
  background-image: -moz-linear-gradient(0deg, rgb(235, 241, 247) 0%, rgba(235, 241, 247, 0) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(235, 241, 247) 0%, rgba(235, 241, 247, 0) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(235, 241, 247) 0%, rgba(235, 241, 247, 0) 100%);
  z-index: 1;
}



.motto-one__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 78.7%;
  opacity: .10;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}


/*--------------------------------------------------------------
# Counter One
--------------------------------------------------------------*/
.counter-one {
  position: relative;
  display: block;
  padding-bottom: 120px;
}

.counter-one__inner {
  position: relative;
  display: block;
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--sinace-primary);
  padding: 100px 0 115px;
  z-index: 1;
}

.counter-one__shadow {
  content: "";
  position: absolute;
  top: 0;
  left: 186px;
  bottom: 0;
  width: 459px;
  background-image: -moz-linear-gradient(0deg, rgb(235, 241, 247) 0%, rgba(235, 241, 247, 0) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(235, 241, 247) 0%, rgba(235, 241, 247, 0) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(235, 241, 247) 0%, rgba(235, 241, 247, 0) 100%);
  z-index: 1;
}

.counter-one__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 78.7%;
  opacity: .10;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.counter-one__left {
  position: relative;
  display: block;
  z-index: 2;
}

.counter-one__left .section-title {
  margin-bottom: 30px;
}

.counter-one__right {
  position: relative;
  display: block;
  margin-left: 70px;
  z-index: 5;
}

.counter-one__count-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.counter-one__count-box li {
  position: relative;
  text-align: center;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  width: 100%;
  margin-bottom: 21px;
}

.counter-one__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 116px;
  height: 116px;
  border-radius: 50%;
  background-color: var(--sinace-white);
  margin: 0 auto;
  z-index: 1;
}

.counter-one__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 50%;
  background-color: var(--sinace-black);
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.counter-one__count-box li:hover .counter-one__icon:before {
  transform: scaleX(1);
}

.counter-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 63px;
  color: var(--sinace-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.counter-one__count-box li:hover .counter-one__icon span {
  transform: scale(0.9);
  color: var(--sinace-white);
}

.counter-one__count {
  position: relative;
  display: block;
  margin-top: 12px;
  margin-bottom: 4px;
}

.counter-one__count h3 {
  font-size: 50px;
  font-weight: 500;
  line-height: 50px;
  color: var(--sinace-black);
  letter-spacing: 0;
}

.counter-one__bottom {
  position: relative;
  display: block;
}

.counter-one__bottom .container {
  max-width: 1280px;
}

.counter-one__bottom-inner {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 31px 40px 34px;
  padding-right: 0;
  margin-top: -40px;
  z-index: 1;
}

.counter-one__bottom-text {
  font-size: 20px;
}

.counter-one__bottom-text a {
  font-weight: 600;
  color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.counter-one__bottom-text a:hover {
  color: var(--sinace-black);
}

.counter-one__call-box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 329px;
  width: 100%;
  background-color: var(--sinace-base);
  display: flex;
  align-items: center;
  padding-left: 65px;
  padding-right: 40px;
  padding-top: 33px;
  padding-bottom: 32px;
}

.counter-one__call-box p {
  font-size: 18px;
  color: var(--sinace-white);
}

.counter-one__call-box p a {
  font-weight: 500;
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.counter-one__call-box p a:hover {
  color: var(--sinace-black);
}

.counter-one__call-icon {
  position: absolute;
  top: 50%;
  left: -27px;
  transform: translateY(-50%);
}

.counter-one__call-icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  font-size: 19px;
  color: var(--sinace-base);
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.counter-one__call-icon span:hover {
  color: var(--sinace-white);
  background-color: var(--sinace-black);
}

/*--------------------------------------------------------------
# Brand One
--------------------------------------------------------------*/
.brand-one {
  position: relative;
  display: block;
  padding: 97px 0 60px;
}

.brand-one__title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 1170px;
  text-align: center;
  margin: 0 auto;
}

.brand-one__title:before {
  position: absolute;
  left: 0;
  top: 14px;
  content: "";
  height: 1px;
  background-color: var(--sinace-bdr-color);
  max-width: 530px;
  width: 100%;
  transform: translateY(-50%);
}

.brand-one__title:after {
  position: absolute;
  top: 14px;
  right: 0;
  content: "";
  height: 1px;
  background-color: var(--sinace-bdr-color);
  max-width: 530px;
  width: 100%;
  transform: translateY(-50%);
}

.brand-one__carousel {
  position: relative;
  display: block;
}

.brand-one__single {
  position: relative;
  display: block;
  text-align: center;
  z-index: 1;
}

.brand-one__img:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sinace-primary);
  opacity: 0;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  z-index: -1;
}

.brand-one__single:hover .brand-one__img:before {
  opacity: 1;
}

.brand-one__img {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.brand-one__img>img {
  position: relative;
  -webkit-transition: 500ms;
  transition: 500ms;
  opacity: 20;
}

.brand-one__single:hover .brand-one__img>img {
  opacity: 50;
}

.brand-one__carousel.owl-theme .owl-nav {
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-50%);
}

.brand-one__carousel .owl-nav.disabled {
  display: inherit;
}

.brand-one__carousel.owl-theme .owl-nav .owl-next {
  height: 27px;
  width: 26px;
  line-height: 26px;
  border-radius: 0;
  color: var(--sinace-black);
  background-color: var(--sinace-primary);
  font-size: 14px;
  margin: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.brand-one__carousel.owl-theme .owl-nav .owl-prev {
  height: 27px;
  width: 26px;
  line-height: 26px;
  border-radius: 0;
  color: var(--sinace-black);
  background-color: var(--sinace-primary);
  font-size: 14px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
  display: inline-block;
}

.brand-one__carousel.owl-theme .owl-nav .owl-next {
  margin-left: 2.5px;
}

.brand-one__carousel.owl-theme .owl-nav .owl-prev {
  margin-right: 2.5px;
}

.brand-one__carousel.owl-theme .owl-nav .owl-next span,
.brand-one__carousel.owl-theme .owl-nav .owl-prev span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-one__carousel.owl-theme .owl-nav .owl-next:hover,
.brand-one__carousel.owl-theme .owl-nav .owl-prev:hover {
  background-color: var(--sinace-base);
  color: var(--sinace-white);
}

/*--------------------------------------------------------------
# Contact One
--------------------------------------------------------------*/
.contact-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  background-color: var(--sinace-black);
  z-index: 1;
}

.contact-one::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0px;
  height: 1px;
  width: 1170px;
  background-color: var(--sinace-white);
  transform: translateX(-50%);
  z-index: 1;
}

.contact-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .60;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.contact-one__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.contact-one__shape-1 img {
  width: auto;
}

.contact-one__left {
  position: relative;
  display: block;
  margin-right: 100px;
  padding: 50px 50px 50px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
}

.contact-one__form {
  position: relative;
  display: block;
}

.contact-one__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.contact-one__input-box input[type="text"],
.contact-one__input-box input[type="email"] {
  height: 58px;
  width: 100%;
  border: none;
  background-color: var(--sinace-primary);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  color: var(--sinace-gray);
  display: block;
  font-weight: 400;
}

.contact-one__input-box textarea {
  font-size: 14px;
  color: var(--sinace-gray);
  height: 138px;
  width: 100%;
  background-color: var(--sinace-primary);
  padding: 15px 30px 30px;
  border: none;
  outline: none;
  margin-bottom: 0px;
  font-weight: 500;
}

.contact-one__input-box.text-message-box {
  height: 138px;
}

.contact-one__btn {
  border: none;
}

.contact-one__right {
  position: relative;
  display: block;
}

.contact-one__right .section-title {
  margin-bottom: 41px;
}

.contact-one__right .section-title__tagline {
  color: var(--sinace-white);
}

.contact-one__right .section-title__title {
  color: var(--sinace-white);
}

.contact-one__points {
  position: relative;
  display: block;
}

.contact-one__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.contact-one__points li+li {
  margin-top: 20px;
}

.contact-one__points li .icon {
  position: relative;
  display: inline-block;
}

.contact-one__points li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  font-size: 22px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-one__points li .icon span:hover {
  color: var(--sinace-base);
  background-color: var(--sinace-white);
}

.contact-one__points li .text {
  position: relative;
  display: block;
  margin-left: 30px;
}

.contact-one__points li .text p {
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--sinace-white);
}

.contact-one__points li .text h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--sinace-white);
  letter-spacing: 0;
  margin-top: 1px;
}

.contact-one__points li .text h3 a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-one__points li .text h3 a:hover {
  color: var(--sinace-base);
}

/*--------------------------------------------------------------
# News One
--------------------------------------------------------------*/
.news-one {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.news-one__single {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
}

.news-one__img-box {
  position: relative;
  display: block;
}

.news-one__img {
  position: relative;
  display: block;
  overflow: hidden;
}

.news-one__img img {
  width: 100%;
  transition: all 500ms ease;
  transform: scale(1);
}

.news-one__single:hover .news-one__img img {
  transform: scale(1.05);
}

.news-one__img>a {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--sinace-black-rgb), 0.4);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: var(--thm-base);
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-30%);
}

.news-one__img>a>span {
  position: relative;
}

.news-one__img>a>span::before {
  content: '';
  width: 20px;
  height: 2px;
  background-color: var(--sinace-base);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 500ms ease;
}

.news-one__img>a>span::after {
  content: '';
  transition: all 500ms ease;
  width: 2px;
  height: 20px;
  background-color: var(--sinace-base);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.news-one__img>a:hover>span::before,
.news-one__img>a:hover>span::after {
  background-color: var(--sinace-white);
}

.news-one__single:hover .news-one__img>a {
  visibility: visible;
  transform: translateY(0%);
  opacity: 1;
}

.news-one__date {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: var(--sinace-base);
  padding: 10px 20px 12px;
}

.news-one__date p {
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: var(--sinace-white);
}

.news-one__content {
  position: relative;
  display: block;
  padding: 22px 40px 34px;
  background-position: center top;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: scaleY(1);
}

.news-one__single:hover .news-one__content {
  transform: scaleY(0.0);
}

.news-one__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-one__meta li {
  position: relative;
  display: flex;
  align-items: center;
}

.news-one__meta li+li {
  margin-left: 13px;
}

.news-one__meta li .icon {
  position: relative;
  display: inline-block;
}

.news-one__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--sinace-base);
}

.news-one__meta li .text {
  margin-left: 4px;
}

.news-one__meta li .text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.news-one__meta li>span {
  font-size: 14px;
  margin-right: 12px;
}

.news-one__title {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 4px;
  padding-bottom: 17px;
  margin-bottom: 12px;
  border-bottom: 2px solid var(--sinace-bdr-color);
}

.news-one__title::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 70px;
  background-color: var(--sinace-base);
}

.news-one__title a {
  color: var(--sinace-black);
}

.news-one__text {
  font-size: 15px;
}

.news-one__hover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center top;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: 2;
}

.news-one__single:hover .news-one__hover {
  transform: scaleY(1.0);
}

.news-one__hover-content {
  position: relative;
  display: block;
  padding: 18px 40px 23px;
}

.news-one__hover-title {
  position: relative;
  font-size: 22px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 4px;
  padding-bottom: 17px;
  margin-bottom: 12px;
  border-bottom: 2px solid var(--sinace-bdr-color);
}

.news-one__hover-title::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 70px;
  background-color: var(--sinace-base);
}

.news-one__hover-title a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-one__hover-title a:hover {
  color: var(--sinace-base);
}

.news-one__hover-text {
  font-size: 15px;
}

.news-one__hover-btn-box {
  position: relative;
  display: block;
}

.news-one__hover-btn-box a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--sinace-black);
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: var(--sinace-white);
  padding: 15px 40px 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-one__hover-btn-box a:hover {
  color: var(--sinace-base);
}

.news-one__hover-btn-box a span {
  font-size: 14px;
}

/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.site-footer {
  position: relative;
  display: block;
  background-color: var(--sinace-black);
  overflow: hidden;
  z-index: 1;
}

.site-footer__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.site-footer__shape-1 {
  position: absolute;
  top: -105px;
  right: -30px;
  opacity: .10;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.site-footer__shape-1 img {
  width: auto;
}

.site-footer__top {
  position: relative;
  display: block;
  padding: 93px 0 90px;
}

.footer-widget__title-box {
  position: relative;
  display: block;
  margin-bottom: 13px;
  padding-left: 13px;
}

.footer-widget__title-box::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 6px solid transparent;
  border-left: 7px solid var(--sinace-base);
  border-bottom: 6px solid transparent;
  transform: translateY(-50%);
}

.footer-widget__title {
  font-size: 20px;
  color: var(--sinace-white);
  line-height: 30px;
  font-weight: 600;
}

.footer-widget__about {
  position: relative;
  display: block;
  margin-right: -26px;
  margin-top: 7px;
}

.footer-widget__logo {
  position: relative;
  display: block;
  margin-bottom: 12px;
}

.footer-widget__about-text {
  font-size: 15px;
  font-weight: 400;
  color: #7e8a9b;
}

.site-footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.site-footer__social a {
  position: relative;
  height: 42px;
  width: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--sinace-white);
  background-color: #0b1a31;
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.site-footer__social a:hover {
  color: var(--sinace-base);
  background-color: var(--sinace-white);
}

.site-footer__social a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--sinace-white);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.site-footer__social a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.site-footer__social a+a {
  margin-left: 10px;
}

.footer-widget__link {
  position: relative;
  display: block;
  margin-left: 69px;
}

.footer-widget__link-list {
  position: relative;
  display: block;
}

.footer-widget__link-list li+li {
  margin-top: 5px;
}

.footer-widget__link-list li a {
  position: relative;
  color: #7e8a9b;
  font-size: 15px;
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__link-list li a:hover {
  color: var(--sinace-white);
}

.footer-widget__newsletter {
  position: relative;
  display: block;
}

.footer-widget__newsletter .footer-widget__title-box {
  margin-bottom: 22px;
}

.footer-widget__newsletter-form-input-box {
  position: relative;
  display: block;
}

.footer-widget__newsletter-form-input-box input[type="email"] {
  height: 50px;
  width: 100%;
  background-color: var(--sinace-white);
  outline: none;
  font-size: 13px;
  color: var(--sinace-gray);
  font-weight: 400;
  border: none;
  padding-right: 70px;
  padding-left: 20px;
}

.footer-widget__newsletter-btn {
  position: absolute;
  top: 50%;
  right: 5px;
  border: none;
  width: 40px;
  height: 40px;
  background-color: var(--sinace-base);
  border-radius: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-widget__newsletter-btn:hover {
  background-color: var(--sinace-black);
}

.footer-widget__newsletter-btn span {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: var(--sinace-white);
}

.footer-widget__Contact-list {
  position: relative;
  display: block;
  margin-top: 20px;
}

.footer-widget__Contact-list li {
  position: relative;
  display: flex;
}

.footer-widget__Contact-list li+li {
  margin-top: 6px;
}

.footer-widget__Contact-list li .icon {
  margin-right: 14px;
}

.footer-widget__Contact-list li .icon span {
  color: var(--sinace-base);
  font-size: 15px;
}

.footer-widget__Contact-list li .text a {
  font-size: 15px;
  font-weight: 600;
  color: var(--sinace-white);
  line-height: 26px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__Contact-list li .text a:hover {
  color: var(--sinace-base);
  transition: all 500ms ease;
}

.footer-widget__portfolio {
  position: relative;
  display: block;
  margin-right: 10px;
}

.footer-widget__portfolio .footer-widget__title-box {
  margin-bottom: 22px;
}

.footer-widget__portfolio-list {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.footer-widget__portfolio-list li {
  position: relative;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.footer-widget__portfolio-img {
  position: relative;
  display: block;
  overflow: hidden;
  width: 80px;
  z-index: 1;
}

.footer-widget__portfolio-img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--sinace-black-rgb), .30);
  transition: all 700ms ease;
  transform: translateY(-100%);
  z-index: 1;
}

.footer-widget__portfolio-list li:hover .footer-widget__portfolio-img:before {
  transform: translateY(0%);
}

.footer-widget__portfolio-img img {
  width: 100%;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.footer-widget__portfolio-list li:hover .footer-widget__portfolio-img img {
  transform: scale(1.1) rotate(2deg);
}

.footer-widget__portfolio-img a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: var(--sinace-white);
  transform: translateY(100px);
  opacity: 0;
  transition: all 700ms ease;
  z-index: 2;
}

.footer-widget__portfolio-list li:hover .footer-widget__portfolio-img a {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}

.footer-widget__portfolio-list li .footer-widget__portfolio-img a:hover {
  color: var(--sinace-base);
}

.site-footer__bottom {
  position: relative;
  display: block;
  padding: 27px 0 29px;
  background-color: var(--sinace-base);
}

.site-footer__bottom-inner {
  position: relative;
  display: block;
  text-align: center;
}

.site-footer__bottom-text {
  color: var(--sinace-white);
  font-size: 15px;
  font-weight: 400;
}

.site-footer__bottom-text a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom-text a:hover {
  color: var(--sinace-black);
}

/*--------------------------------------------------------------
# Feature One
--------------------------------------------------------------*/
.feature-one {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.feature-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.feature-one__img-box {
  position: relative;
  display: block;
}

.feature-one__img {
  position: relative;
  display: block;
}

.feature-one__img img {
  width: 100%;
}

.feature-one__title-box {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 27px 30px 29px;
  background-color: rgba(var(--sinace-base-rgb), .90);
  background-position: center top;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(1);
}

.feature-one__single:hover .feature-one__title-box {
  transform: scaleY(0.0);
}

.feature-one__title-box::before {
  content: "";
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 31px solid transparent;
  border-right: 31px solid transparent;
  border-bottom: 10px solid rgba(var(--sinace-base-rgb), .90);
}

.feature-one__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}

.feature-one__title a {
  color: var(--sinace-white);
}

.feature-one__hover-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px 40px 40px;
  background-color: rgba(var(--sinace-black-rgb), .90);
  background-position: center top;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .5s;
  transition-property: all;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
}

.feature-one__single:hover .feature-one__hover-content {
  transform: scaleY(1.0);
}

.feature-one__icon-and-title {
  position: relative;
  display: flex;
  align-items: center;
}

.feature-one__icon {
  position: relative;
  display: inline-block;
}

.feature-one__icon span {
  position: relative;
  display: block;
  float: left;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.feature-one__icon:hover span {
  transform: scale(0.9);
}

.feature-one__hover-title {
  margin-left: 8px;
}

.feature-one__hover-title h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.feature-one__hover-title h3 a {
  color: var(--sinace-white);
  transition: all 500ms ease;
}

.feature-one__hover-title h3 a:hover {
  color: var(--sinace-base);
}

.feature-one__hover-text {
  color: rgba(var(--sinace-white-rgb), .70);
  margin-top: 17px;
  margin-bottom: 30px;
}

.feature-one__hover-btn-box {
  position: relative;
  display: block;
}

.feature-one__hover-btn {
  font-size: 13px;
  font-weight: 400;
  padding: 4px 20px 6px;
}

.feature-one__hover-btn:hover {
  color: var(--sinace-base);
}

.feature-one__hover-btn::before {
  background-color: var(--sinace-white);
}

/*--------------------------------------------------------------
# About Two
--------------------------------------------------------------*/
.about-two {
  position: relative;
  display: block;
  background-color: var(--sinace-primary);
  z-index: 1;
}

.about-two__shape-3 {
  position: absolute;
  left: 0;
  top: 0;
  opacity: .15;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.about-two__shape-3 img {
  width: auto;
}

.about-two__left {
  position: relative;
  display: block;
  margin-right: -118px;
  margin-left: -80px;
  margin-top: -93px;
}

.about-two__img-box {
  position: relative;
  display: block;
}

.about-two__img {
  position: relative;
  display: block;
  mix-blend-mode: darken;
}

.about-two__img img {
  width: 100%;
}

.about-two__successful-project {
  position: absolute;
  top: 265px;
  left: 66px;
  max-width: 163px;
  width: 100%;
  background-color: var(--sinace-base);
  text-align: center;
  padding: 43px 0 48px;
  border-radius: 50%;
}

.about-two__successful-project h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px !important;
  font-family: var(--sinace-font) !important;
  color: var(--sinace-white);
  letter-spacing: 0;
}

.about-two__successful-project p {
  color: var(--sinace-white);
  line-height: 19px;
}

.about-two__shape-1 {
  position: absolute;
  left: 212px;
  bottom: 156px;
}

.about-two__shape-1 img {
  width: auto;
}

.about-two__right {
  position: relative;
  display: block;
  margin-left: 70px;
  padding-top: 120px;
}

.about-two__right .section-title {
  margin-bottom: 33px;
}

.about-two__text {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: var(--sinace-letter-spacing);
  color: var(--sinace-black);
}

.about-two__text-2 {
  margin-top: 1px;
}

.about-two__points {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 23px;
}

.about-two__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.about-two__points li+li {
  margin-left: 103px;
}

.about-two__points li:last-child::before {
  display: none;
}

.about-two__points li::before {
  content: "";
  position: absolute;
  top: 10px;
  right: -58px;
  bottom: 5px;
  width: 3px;
  background-color: #d9e1e9;
}

.about-two__points li .icon {
  position: relative;
  display: inline-block;
  top: 5px;
}

.about-two__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--sinace-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.about-two__points li:hover .icon span {
  transform: scale(0.9);
}


.about-two__points li .text {
  margin-left: 17px;
}

.about-two__points li .text p {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--sinace-black);
  letter-spacing: var(--sinace-letter-spacing);
}

.about-two__progress {
  position: relative;
  display: block;
}

.about-two__progress-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: var(--sinace-black);
  margin-bottom: 15px;
}

.about-two__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: var(--sinace-white);
  border-radius: 6px;
}

.about-two__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 5px;
  background-color: var(--sinace-base);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.about-two__progress .bar-inner::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -3px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: var(--sinace-black);
  border: 2px solid var(--sinace-white);
  transform: translateY(-50%);
}

.about-two__progress .count-text {
  position: absolute;
  right: -6px;
  bottom: 26px;
  color: var(--sinace-gray);
  line-height: 26px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-two__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.about-two__progress .bar.marb-0 {
  margin-bottom: 0;
}

.about-two__btn-and-founder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.about-two__btn-box {
  position: relative;
  display: block;
}

.about-two__btn {
  background-color: var(--sinace-black);
}

.about-two__btn::before {
  background-color: var(--sinace-base);
}

.about-two__founder {
  position: relative;
  display: block;
}

.about-two__founder h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: var(--sinace-black);
  letter-spacing: 0;
}

.about-two__founder p {
  position: relative;
  margin-top: -4px;
}

.about-two__shape-2 {
  position: absolute;
  top: 2px;
  left: -93px;
}

.about-two__shape-2 img {
  width: auto;
}

/*--------------------------------------------------------------
# Services Two
--------------------------------------------------------------*/
.services-two {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.services-two__bg-box {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 424px;
  background-color: var(--sinace-black);
  z-index: -1;
}

.services-two__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.services-two .section-title__tagline {
  color: #7e8a9b;
}

.services-two .section-title__title {
  color: var(--sinace-white);
}

.services-two__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.services-two__img-box {
  position: relative;
  display: block;
}

.services-two__img {
  position: relative;
  display: block;
}

.services-two__img img {
  width: 100%;
}

.services-two__icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.services-two__icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 67px;
  font-size: 32px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
}

.services-two__content {
  position: relative;
  display: block;
  padding: 23px 30px 25px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.services-two__content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6px;
  background-color: var(--sinace-base);
}

.services-two__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.services-two__title a {
  color: #222232;
}

.services-two__text {
  margin-top: 11px;
}

.services-two__hover-single {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: bottom left;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  z-index: 2;
}

.services-two__single:hover .services-two__hover-single {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.services-two__hover-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.services-two__hover-img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6px;
  background-color: var(--sinace-base);
  z-index: 2;
}

.services-two__hover-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(8, 21, 40, .80);
  z-index: 1;
}

.services-two__hover-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 67px;
  top: 0;
  right: 0;
  background-color: var(--sinace-base);
  z-index: 2;
}

.services-two__hover-icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--sinace-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.services-two__hover-single:hover .services-two__hover-icon span {
  transform: scale(0.9);
}

.services-two__hover-content {
  position: absolute;
  bottom: 25px;
  left: 30px;
  z-index: 2;
}

.services-two__hover-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.services-two__hover-title a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-two__hover-title a:hover {
  color: var(--sinace-base);
}

.services-two__hover-text {
  margin-top: 11px;
  color: rgba(var(--sinace-white-rgb), .60);
}

/*--------------------------------------------------------------
# FAQ One
--------------------------------------------------------------*/
.faq-one {
  position: relative;
  display: block;
  padding-bottom: 120px;
  z-index: 1;
}

.faq-one__left {
  position: relative;
  display: block;
}

.faq-one__left .section-title {
  margin-bottom: 14px;
}

.faq-one__experience-and-points-box {
  position: relative;
  display: flex;
}

.faq-one__experience {
  position: relative;
  display: block;
  margin-right: 37px;
}

.faq-one__experience-title-box {
  position: relative;
  display: block;
  z-index: 1;
}

.faq-one__experience-title-box-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.faq-one__experience-title {
  font-size: 150px;
  font-weight: 700;
  line-height: 150px;
  letter-spacing: 0;
  background-color: var(--sinace-black);
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  background-position: center center;
  color: rgba(0, 0, 0, 0);
}

.faq-one__experience p {
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  color: var(--sinace-black);
  margin-top: 3px;
}

.faq-one__points {
  position: relative;
  display: block;
  margin-top: 18px;
}

.faq-one__points li {
  position: relative;
  display: flex;
}

.faq-one__points li+li {
  margin-top: 43px;
}

.faq-one__points li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 7px;
  max-width: 52px;
  width: 100%;
  height: 52px;
  border-radius: 50%;
  background-color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one__points li:hover .icon {
  background-color: var(--sinace-black);
}

.faq-one__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one__points li .content {
  position: relative;
  display: block;
  margin-left: 20px;
}

.faq-one__points li .content h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 1px;
}

.faq-one__right {
  position: relative;
  display: block;
}

.faq-one-accrodion .accrodion {
  position: relative;
  display: block;
  background-color: #eef3f8;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion-title {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 23px 40px 24px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  padding-right: 25px;
}

.faq-one-accrodion .accrodion-title h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: var(--sinace-black);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion.active .accrodion-title h4 {
  color: var(--sinace-base);
}

.faq-one-accrodion .accrodion+.accrodion {
  margin-top: 10px;
}

.faq-one-accrodion .accrodion-title h4::before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 13px;
  color: var(--sinace-base);
  position: absolute;
  top: 50%;
  right: 0;
  line-height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-align: center;
  width: 21px;
  height: 21px;
}

.faq-one-accrodion .accrodion.active .accrodion-title h4::before {
  content: "\f068";
  color: var(--sinace-black);
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-one-accrodion .accrodion-content {
  position: relative;
  padding-bottom: 32px;
  margin-top: -11px;
  padding-left: 40px;
  padding-right: 35px;
}

.faq-one-accrodion .accrodion-content p {
  margin: 0;
}

/*--------------------------------------------------------------
# Testimonial Two
--------------------------------------------------------------*/
.testimonial-two {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 120px 0 120px;
  z-index: 1;
}

.testimonial-two__bg-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 483px;
  background-image: -moz-linear-gradient(90deg, rgba(254, 253, 253, 0) 0%, rgb(237, 242, 248) 100%);
  background-image: -webkit-linear-gradient(90deg, rgba(254, 253, 253, 0) 0%, rgb(237, 242, 248) 100%);
  background-image: -ms-linear-gradient(90deg, rgba(254, 253, 253, 0) 0%, rgb(237, 242, 248) 100%);
  z-index: -1;
}

.testimonial-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 483px;
  opacity: .50;
  mix-blend-mode: luminosity;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.testimonial-two__bottom {
  position: relative;
  display: block;
}

.testimonial-two__carousel {
  position: relative;
  display: block;
}

.testimonial-two__single {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  padding: 40px 50px 33px;
  padding-right: 160px;
  z-index: 1;
}

.testimonial-two__client-img-and-info {
  position: relative;
  display: flex;
  align-items: center;
}

.testimonial-two__client-img {
  position: relative;
  display: block;
  width: 87px;
  border-radius: 50%;
}

.testimonial-two__client-img img {
  width: 100%;
  border-radius: 50%;
  border: 4px solid var(--sinace-base);
}

.testimonial-two__client-info {
  position: relative;
  display: block;
  margin-left: 20px;
}

.testimonial-two__client-info h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.testimonial-two__client-info h3 a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-two__client-info h3 a:hover {
  color: var(--sinace-base);
}

.testimonial-two__client-info p {
  font-size: 14px;
  color: var(--sinace-base);
  line-height: 26px;
}

.testimonial-two__text {
  font-size: 18px;
  line-height: 34px;
  margin-top: 21px;
}

.testimonial-two__shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  opacity: .20;
  z-index: -1;
}

.testimonial-two__shape-1 img {
  width: auto;
}

.testimonial-two__shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.testimonial-two__shape-2 img {
  width: auto;
}

.testimonial-two__shape-3 {
  position: absolute;
  top: 34px;
  right: 0;
  z-index: -1;
}

.testimonial-two__shape-3 img {
  width: auto;
}

.testimonial-two__shape-4 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.testimonial-two__shape-4 img {
  width: auto;
}

.testimonial-two__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-two__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}

.testimonial-two__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

/*--------------------------------------------------------------
# Project Two
--------------------------------------------------------------*/
.project-two {
  overflow-x: hidden;
  position: relative;
  display: block;
  z-index: 1;
}

.project-two__inner {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.project-two__inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10000px;
  right: 100px;
  bottom: 0;
  background-color: var(--sinace-black);
  z-index: -1;
}

.project-two__bg {
  position: absolute;
  top: 0;
  left: -375px;
  bottom: 0;
  width: 90.75%;
  opacity: .10;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  z-index: -1;
}

.project-two__left {
  position: relative;
  display: block;
  margin-right: 40px;
  z-index: 5;
}

.project-two__left .section-title {
  margin-bottom: 31px;
}

.project-two__left .section-title__tagline {
  color: #7e8a9b;
}

.project-two__left .section-title__title {
  color: var(--sinace-white);
}

.project-two__text {
  color: #7e8a9b;
}

.project-two__completed-box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--sinace-base);
  padding: 18px 20px 17px;
  margin-top: 41px;
}

.project-two__completed-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-two__completed-icon:hover {
  background-color: var(--sinace-white);
}

.project-two__completed-icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-two__completed-icon:hover span {
  color: var(--sinace-black);
}

.project-two__completed-content {
  position: relative;
  display: block;
  margin-left: 24px;
  padding-left: 32px;
}

.project-two__completed-content::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 0;
  bottom: -17px;
  width: 2px;
  background-color: rgba(var(--sinace-white-rgb), .10);
}

.project-two__count {
  position: relative;
  display: block;
}

.project-two__count h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  color: var(--sinace-white);
  letter-spacing: 0;
}

.project-two__completed-content p {
  color: var(--sinace-white);
  margin-top: 5px;
}

.project-two__right {
  position: relative;
  display: block;
  margin-right: -375px;
}

.project-two__carousel {
  position: relative;
  display: block;
}

.project-two__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.project-two__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}

.project-two__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

.project-two__single {
  position: relative;
  display: block;
}

.project-two__img-box {
  position: relative;
  display: block;
}

.project-two__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.project-two__img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(12, 38, 52, .60);
  opacity: 1;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  -webkit-transform: perspective(400px) rotateY(-90deg) scale(0.2);
  -ms-transform: perspective(400px) rotateY(-90deg) scale(0.2);
  transform: perspective(400px) rotateY(-90deg) scale(0.2);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  z-index: 1;
}

.project-two__single:hover .project-two__img:before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateY(0deg) scale(1.0);
  -ms-transform: perspective(400px) rotateY(0deg) scale(1.0);
  transform: perspective(400px) rotateY(0deg) scale(1.0);
}

.project-two__img img {
  width: 100%;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.project-two__single:hover .project-two__img img {
  transform: scale(1.1) rotate(2deg);
}

.project-two__title-box {
  position: relative;
  display: block;
  text-align: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 19px 20px 23px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-two__single:hover .project-two__title-box {
  background-color: rgb(15, 33, 60);
}

.project-two__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
}

.project-two__title a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-two__single:hover .project-two__title a {
  color: var(--sinace-white);
}

.project-two__tagline {
  position: absolute;
  bottom: 42px;
  right: -33px;
  background-color: var(--sinace-base);
  padding: 14px 25px 14px;
  transform: rotate(90deg);
  z-index: 2;
}

.project-two__tagline p {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--sinace-white);
}

.project-two__carousel.owl-theme .owl-nav {
  position: absolute;
  bottom: -120px;
  right: 40.6%;
}

.project-two__carousel.owl-theme .owl-nav .owl-next {
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 0;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  font-size: 16px;
  margin: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-two__carousel.owl-theme .owl-nav .owl-prev {
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 0;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  font-size: 16px;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
  display: inline-block;
}

.project-two__carousel.owl-theme .owl-nav .owl-next span,
.project-two__carousel.owl-theme .owl-nav .owl-prev span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-two__carousel.owl-theme .owl-nav .owl-next:hover,
.project-two__carousel.owl-theme .owl-nav .owl-prev:hover {
  background-color: var(--sinace-white);
  color: var(--sinace-black);
}

/*--------------------------------------------------------------
# Brand Two
--------------------------------------------------------------*/
.brand-two {
  position: relative;
  display: block;
}

.brand-two__inner {
  position: relative;
  display: block;
  padding: 83px 0 60px;
  border-bottom: 1px solid var(--sinace-bdr-color);
}

.brand-two__carousel {
  position: relative;
  display: block;
}

.brand-two__single {
  position: relative;
  display: block;
  text-align: center;
  z-index: 1;
}

.brand-two__img:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sinace-primary);
  opacity: 0;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  z-index: -1;
}

.brand-two__single:hover .brand-two__img:before {
  opacity: 1;
}

.brand-two__img {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.brand-two__img>img {
  position: relative;
  -webkit-transition: 500ms;
  transition: 500ms;
  opacity: 0.2;
}

.brand-two__single:hover .brand-two__img>img {
  opacity: 0.60;
}

/*--------------------------------------------------------------
# Get Free
--------------------------------------------------------------*/
.get-free {
  position: relative;
  display: block;
  padding: 100px 0 120px;
  overflow: hidden;
  z-index: 1;
}

.get-free__bg-box {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 58.6%;
  background-color: var(--sinace-black);
  z-index: -1;
}

.get-free__bg-two {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 41.5%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  z-index: -1;
}

.get-free__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.04;
  z-index: -1;
}

.get-free__shape-1 img {
  width: auto;
}

.get-free__shape-2 {
  position: absolute;
  top: 0;
  right: -30px;
  opacity: .70;
}

.get-free__shape-2 img {
  width: auto;
}

.get-free__shape-3 {
  position: absolute;
  bottom: -30px;
  right: 0;
}

.get-free__shape-3 img {
  width: auto;
}

.get-free__shape-4 {
  position: absolute;
  bottom: -30px;
  right: 0;
  opacity: .70;
}

.get-free__shape-4 img {
  width: auto;
}

.get-free__shape-5 {
  position: absolute;
  bottom: -30px;
  right: 0;
}

.get-free__shape-5 img {
  width: auto;
}

.get-free__inner {
  position: relative;
  display: flex;
  align-items: center;
}

.get-free__left {
  position: relative;
  display: block;
}

.get-free__left .section-title {
  margin-bottom: 31px;
}

.get-free__left .section-title__tagline {
  color: #7e8a9b;
}

.get-free__left .section-title__title {
  color: var(--sinace-white);
}

.get-free__text {
  color: #7e8a9b;
}

.get-free__btn-box {
  position: relative;
  display: block;
  margin-top: 41px;
}

.get-free__btn:hover {
  color: var(--sinace-black);
}

.get-free__btn::before {
  background-color: var(--sinace-white);
}

.get-free__right {
  position: relative;
  display: block;
  margin-left: 80px;
}

.get-free__contact {
  position: relative;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  padding: 33px 30px 25px;
}

.get-free__contact::before {
  content: "";
  position: absolute;
  bottom: -23px;
  left: 50px;
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-top: 23px solid var(--sinace-base);
}

.get-free__contact a {
  font-weight: 500;
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-free__contact a:hover {
  color: var(--sinace-black);
}

.get-free__contact-icon {
  position: absolute;
  top: -27px;
  left: 30px;
  width: 53px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-free__contact-icon:hover {
  background-color: var(--sinace-base);
}

.get-free__contact-icon span {
  position: relative;
  display: inline-block;
  font-size: 19px;
  color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-free__contact-icon:hover span {
  color: var(--sinace-white);
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map {
  position: relative;
  display: block;
  background-color: var(--sinace-primary);
}

.google-map__one {
  position: relative;
  display: block;
  border: none;
  height: 483px;
  width: 100%;
  mix-blend-mode: luminosity;
}

/*--------------------------------------------------------------
# Feature Two
--------------------------------------------------------------*/
.feature-two {
  position: relative;
  display: block;
  background-color: var(--sinace-black);
  padding: 188px 0 120px;
  clip-path: polygon(63% 0, 100% 19%, 100% 100%, 0 100%, 0 17%);
  margin-top: -109px;
  z-index: 22;
}

.feature-two__shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.feature-two__shape-1 img {
  width: auto;
}

.feature-two__single {
  position: relative;
  display: flex;
  align-items: center;
  border: 4px solid var(--sinace-white);
  background-color: var(--sinace-black);
  margin-bottom: 30px;
}

.feature-two__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 110px;
  width: 100%;
  height: 186px;
  background-color: var(--sinace-base);
}

.feature-two__icon::before {
  content: "";
  position: absolute;
  top: 20px;
  right: -12px;
  border-top: 20px solid transparent;
  border-left: 12px solid var(--sinace-base);
  border-bottom: 20px solid transparent;
}

.feature-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 62px;
  color: var(--sinace-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.feature-two__single:hover .feature-two__icon span {
  transform: scale(0.9);
}

.feature-two__content {
  position: relative;
  display: block;
  margin-left: 30px;
}

.feature-two__content h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.feature-two__content h3 a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-two__content h3 a:hover {
  color: var(--sinace-base);
}

.feature-two__content p {
  color: #7e8a9b;
  margin-top: 16px;
}

.feature-two__bottom-text {
  position: relative;
  display: block;
  border: 1px solid #283953;
  padding: 10px 20px 10px;
}

.feature-two__bottom-text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
}

/*--------------------------------------------------------------
# About Three
--------------------------------------------------------------*/
.about-three {
  position: relative;
  display: block;
  padding: 120px 0 180px;
  z-index: 1;
}

.about-three__left {
  position: relative;
  display: block;
  margin-left: 110px;
}

.about-three__img-box {
  position: relative;
  display: block;
}

.about-three__img {
  position: relative;
  display: block;
}

.about-three__img img {
  width: 100%;
}

.about-three__img-2 {
  position: absolute;
  left: -241px;
  top: 79px;
}

.about-three__img-2 img {
  width: auto;
}

.about-three__shape-1 {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: .90;
}

.about-three__shape-1 img {
  width: auto;
}

.about-three__shape-2 {
  position: absolute;
  top: 109px;
  right: 198px;
  bottom: 109px;
  left: -207px;
  border: 6px solid var(--sinace-base);
  z-index: 1;
}

.about-three__right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.about-three__right .section-title {
  margin-bottom: 30px;
}

.about-three__points {
  position: relative;
  display: block;
  margin-top: 31px;
  margin-bottom: 29px;
}

.about-three__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.about-three__points li+li {
  margin-top: 8px;
}

.about-three__points li .icon {
  position: relative;
  display: inline-block;
}

.about-three__points li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  font-size: 9px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  border-radius: 50%;
}

.about-three__points li .text {
  margin-left: 22px;
}

.about-three__points li .text p {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--sinace-black);
}

.about-three__text-two {
  position: relative;
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  color: var(--sinace-base);
  letter-spacing: var(--sinace-letter-spacing);
}

.about-three__text-two::before {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #e2878c;
}

.about-three__person-and-trusted {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: -8px;
}

.about-three__person {
  position: relative;
  display: flex;
  align-items: center;
}

.about-three__person::before {
  content: "";
  position: absolute;
  bottom: -53px;
  left: -10px;
  right: -46px;
  height: 4px;
  background-color: #d9e1e9;
}

.about-three__person-img {
  position: relative;
  display: block;
  width: 67px;
  border-radius: 50%;
}

.about-three__person-img::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  border: 2px solid rgb(207, 3, 23);
}

.about-three__person-img img {
  width: 100%;
  border-radius: 50%;
}

.about-three__person-content {
  position: relative;
  display: block;
  margin-left: 30px;
}

.about-three__person-content h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.about-three__person-content p {
  font-size: 14px;
  line-height: 14px;
  margin-top: 7px;
}

.about-three__trusted {
  position: relative;
  display: block;
  max-width: 180px;
  width: 100%;
  text-align: center;
  padding: 28px 30px 25px;
  margin-left: 76px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.about-three__trusted-shape-1 {
  position: absolute;
  width: 103px;
  height: 103px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  background-image: -moz-linear-gradient(90deg, rgb(235, 241, 247) 0%, rgba(254, 253, 253, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(235, 241, 247) 0%, rgba(254, 253, 253, 0) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(235, 241, 247) 0%, rgba(254, 253, 253, 0) 100%);
  z-index: -1;
}


.about-three__trusted::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--sinace-base);
}

.about-three__trusted-icon {
  position: relative;
  display: inline-block;
}

.about-three__trusted-icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--sinace-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.about-three__trusted:hover .about-three__trusted-icon span {
  transform: scale(0.9);
}

.about-three__trusted-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: var(--sinace-black);
  margin-top: 6px;
}

/*--------------------------------------------------------------
# CTA One
--------------------------------------------------------------*/
.cta-one {
  position: relative;
  display: block;
}

.cta-one__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--sinace-base);
  padding: 73px 60px 80px;
  padding-left: 370px;
}

.cta-one__title {
  position: relative;
  display: block;
}

.cta-one__title h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  color: var(--sinace-white);
}

.cta-one__btn-box {
  position: relative;
  top: 4px;
}

.cta-one__btn {
  color: var(--sinace-black);
  background-color: var(--sinace-white);
}

.cta-one__img {
  position: absolute;
  top: 0;
  left: 0;
}

.cta-one__img img {
  width: auto;
}

/*--------------------------------------------------------------
# Services Three
--------------------------------------------------------------*/
.services-three {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  background-color: var(--sinace-black);
  z-index: 1;
}

.services-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .10;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.services-three .section-title__tagline {
  color: #7e8a9b;
}

.services-three .section-title__title {
  color: var(--sinace-white);
}

/*--------------------------------------------------------------
# Testimonial Three
--------------------------------------------------------------*/
.testimonial-three {
  position: relative;
  display: block;
  background-color: #ebf1f7;
  z-index: 1;
}

.testimonial-three__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 47.9%;
  opacity: .10;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  z-index: -1;
}

.testimonial-three__left {
  position: relative;
  display: block;
  background-color: var(--sinace-base);
  margin-left: -375px;
  margin-right: 70px;
  padding: 88px 100px 100px;
  overflow: hidden;
  z-index: 1;
}

.testimonial-three__shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  opacity: .15;
  mix-blend-mode: multiply;
  z-index: -1;
}

.testimonial-three__shape-1 img {
  width: auto;
}

.testimonial-three__carousel {
  position: relative;
  display: block;
}

.testimonial-three__single {
  position: relative;
  display: block;
}

.testimonial-three__quote {
  position: relative;
  display: inline-block;
}

.testimonial-three__quote span {
  font-size: 96px;
  color: var(--sinace-white);
  position: relative;
  line-height: 96px;
}

.testimonial-three__text {
  font-size: 26px;
  font-weight: 300;
  line-height: 50px;
  color: var(--sinace-white);
  margin-top: -34px;
  margin-bottom: 24px;
}

.testimonial-three__client-info-box {
  position: relative;
  display: flex;
  align-items: center;
}

.testimonial-three__client-img {
  position: relative;
  display: block;
  width: 87px;
  border: 4px solid var(--sinace-black);
  border-radius: 50%;
}

.testimonial-three__client-img img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-three__client-info {
  position: relative;
  display: block;
  margin-left: 20px;
}

.testimonial-three__client-info h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: var(--sinace-white);
}

.testimonial-three__client-info h3 a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonial-three__client-info h3 a:hover {
  color: var(--sinace-black);
}

.testimonial-three__client-info p {
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  color: var(--sinace-white);
  margin-top: 8px;
}

.testimonial-three__right {
  position: relative;
  display: block;
  padding-top: 100px;
}

.testimonial-three__points {
  position: relative;
  display: flex;
  align-items: center;
}

.testimonial-three__points li {
  position: relative;
  display: block;
  padding-bottom: 45px;
  border-bottom: 3px solid var(--sinace-bdr-color);
}

.testimonial-three__points li+li {
  margin-left: 30px;
}

.testimonial-three__points li .icon {
  position: relative;
  display: inline-block;
}

.testimonial-three__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--sinace-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.testimonial-three__points li:hover .icon span {
  transform: scale(.9);
}

.testimonial-three__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 7px;
  margin-bottom: 12px;
}

/*--------------------------------------------------------------
# Project Three
--------------------------------------------------------------*/
.project-three {
  position: relative;
  display: block;
  padding: 120PX 0 90PX;
  z-index: 1;
}

.project-three .container {
  max-width: 1600px;
}

.project-three .project-two__single {
  margin-bottom: 30PX;
}

/*--------------------------------------------------------------
# Together One
--------------------------------------------------------------*/
.together-one {
  position: relative;
  display: block;
  padding-bottom: 120px;
  z-index: 1;
}

.together-one__left {
  position: relative;
  display: block;
}

.together-one .section-title {
  margin-bottom: 31px;
}

.together-one__text-1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: var(--sinace-letter-spacing);
  color: var(--sinace-base);
}

.together-one__text-2 {
  margin-top: 30px;
  margin-bottom: 40px;
}

.together-one__points {
  position: relative;
  display: flex;
  align-items: center;
}

.together-one__points li {
  position: relative;
  display: block;
}

.together-one__points li+li {
  margin-left: 30px;
}

.together-one__points li .icon {
  position: relative;
  display: inline-block;
}

.together-one__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--sinace-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.together-one__points li:hover .icon span {
  transform: scale(0.9);
}

.together-one__points li h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 7px;
  margin-bottom: 6px;
}

.together-one__points li p {
  font-size: 15px;
}

.together-one__right {
  position: relative;
  display: block;
}

.together-one__explore {
  position: relative;
  display: flex;
  background-color: var(--sinace-base);
  border-radius: 50%;
  margin-bottom: 20px;
  height: 270px;
  width: 270px;
  align-items: center;
  justify-content: center;
}

.together-one__explore p {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: var(--sinace-white);
  letter-spacing: var(--sinace-letter-spacing);
}

.together-one__img {
  position: relative;
  display: block;
  margin-left: 48px;
}

.together-one__img img {
  width: 100%;
}

.together-one__advice {
  position: relative;
  display: flex;
  background-color: var(--sinace-base);
  border-radius: 50%;
  margin-top: 19px;
  height: 270px;
  width: 270px;
  align-items: center;
  justify-content: center;
}

.together-one__advice p {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: var(--sinace-white);
  letter-spacing: var(--sinace-letter-spacing);
}

.together-one__img-2 {
  position: relative;
  display: block;
  margin-left: -9px;
  margin-right: 56px;
}

.together-one__img-2 img {
  width: 100%;
}

/*--------------------------------------------------------------
# Expectation One
--------------------------------------------------------------*/
.expectation-one {
  position: relative;
  display: block;
  padding-top: 120px;
  background-color: var(--sinace-black);
  overflow: hidden;
  z-index: 1;
}

.expectation-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .10;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.expectation-one .section-title__tagline {
  color: var(--sinace-white);
}

.expectation-one .section-title__title {
  color: var(--sinace-white);
}

.expectation-one__inner {
  position: relative;
  display: block;
  border-top: 1px solid rgba(var(--sinace-white-rgb), .10);
}

.expectation-one__inner .container {
  max-width: 1700px;
}

.expectation-one__points {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expectation-one__points li {
  position: relative;
  display: block;
  padding-top: 87px;
  padding-bottom: 71px;
}

.expectation-one__points li::before {
  content: "";
  position: absolute;
  top: 0;
  right: -83px;
  bottom: 0;
  width: 1px;
  background-color: rgba(var(--sinace-white-rgb), .10);
}

.expectation-one__points li:last-child::before {
  display: none;
}

.expectation-one__points li+li {
  margin-left: 165px;
}

.expectation-one__points li .icon {
  position: relative;
  display: inline-block;
}

.expectation-one__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--sinace-base);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.expectation-one__points li:hover .icon span {
  transform: scale(.9);
}

.expectation-one__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 15px;
  margin-bottom: 13px;
}

.expectation-one__title a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.expectation-one__title a:hover {
  color: var(--sinace-base);
}

.expectation-one__text {
  color: rgba(var(--sinace-white-rgb), .70);
}


/*--------------------------------------------------------------
# News Two
--------------------------------------------------------------*/
.news-two {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.news-two__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.news-two__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.news-two__img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -moz-linear-gradient(90deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  z-index: 1;
}

.news-two__img img {
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-two__single:hover .news-two__img img {
  transform: scale(1.08);
}

.news-two__date {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  padding: 11px 20px 11px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  z-index: 2;
}

.news-two__title {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 31px;
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
  letter-spacing: -0.04em;
  z-index: 2;
}

.news-two__title a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-two__title a:hover {
  color: var(--sinace-base);
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
  position: relative;
  display: block;
  background-color: var(--sinace-black);
  overflow: hidden;
  z-index: 1;
}

.page-header__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .60;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.page-header__bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 49.4%;
  background-image: -moz-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(15, 33, 60) 0%, rgba(15, 33, 60, 0) 100%);
  z-index: -1;
}

.page-header__inner {
  position: relative;
  display: block;
  padding: 162px 0 164px;
  z-index: 15;
}

.page-header__inner h2 {
  font-size: 46px;
  color: var(--sinace-white);
  line-height: 46px;
  font-weight: 700;
  text-align: center;
}

.thm-breadcrumb__inner {
  position: absolute;
  bottom: 0;
  left: 0;
}

.thm-breadcrumb {
  position: relative;
  display: block;
  background-color: rgba(var(--sinace-white-rgb), .10);
  border: 1px solid rgba(var(--sinace-white-rgb), .10);
  padding: 8px 30px 11px;
}

.thm-breadcrumb li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 500;
  color: var(--sinace-white);
}

.thm-breadcrumb li+li {
  margin-left: 7px;
}

.thm-breadcrumb li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 500;
  color: var(--sinace-white);
}

.thm-breadcrumb li:hover a {
  color: var(--sinace-base);
  opacity: 1;
}

.thm-breadcrumb li span {
  font-size: 14px;
}



/*--------------------------------------------------------------
# News Page
--------------------------------------------------------------*/
.news-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

/*--------------------------------------------------------------
# Contact Page
--------------------------------------------------------------*/
.contact-page {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.contact-page__left {
  position: relative;
  display: block;
}

.contact-page__form-box {
  position: relative;
  display: block;
  padding: 50px 50px 50px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
}

.contact-page__form {
  position: relative;
  display: block;
}

.contact-page__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.contact-page__input-box input[type="text"],
.contact-page__input-box input[type="email"] {
  height: 58px;
  width: 100%;
  border: none;
  background-color: var(--sinace-primary);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  color: var(--sinace-gray);
  display: block;
  font-weight: 400;
}

.contact-page__input-box textarea {
  font-size: 14px;
  color: var(--sinace-gray);
  height: 138px;
  width: 100%;
  background-color: var(--sinace-primary);
  padding: 15px 30px 30px;
  border: none;
  outline: none;
  margin-bottom: 0px;
  font-weight: 500;
}

.contact-page__input-box.text-message-box {
  height: 138px;
}

.contact-page__btn {
  border: none;
}

.contact-page__right {
  position: relative;
  display: block;
  margin-left: 100px;
}

.contact-page__right .section-title {
  margin-bottom: 41px;
}

.contact-page__points {
  position: relative;
  display: block;
}

.contact-page__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.contact-page__points li+li {
  margin-top: 20px;
}

.contact-page__points li .icon {
  position: relative;
  display: inline-block;
}

.contact-page__points li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  font-size: 22px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-page__points li .icon span:hover {
  color: var(--sinace-white);
  background-color: var(--sinace-black);
}

.contact-page__points li .text {
  position: relative;
  display: block;
  margin-left: 30px;
}

.contact-page__points li .text p {
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--sinace-gray);
}

.contact-page__points li .text h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--sinace-black);
  letter-spacing: 0;
  margin-top: 1px;
}

.contact-page__points li .text h3 a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-page__points li .text h3 a:hover {
  color: var(--sinace-base);
}

/*--------------------------------------------------------------
# Google Map Two
--------------------------------------------------------------*/
.google-map-two {
  position: relative;
  display: block;
  background-color: var(--sinace-primary);
}

.google-map__two {
  position: relative;
  display: block;
  border: none;
  height: 492px;
  width: 100%;
  mix-blend-mode: luminosity;
}


/*--------------------------------------------------------------
# News Details
--------------------------------------------------------------*/
.news-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.news-details__left {
  position: relative;
  display: block;
}

.news-details__img {
  position: relative;
  display: block;
}

.news-details__img img {
  width: 100%;
}

.news-details__date {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--sinace-base);
  padding: 10px 20px 12px;
}

.news-details__date p {
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: var(--sinace-white);
}

.news-details__content {
  position: relative;
  display: block;
  padding: 22px 42px 35px;
  border-bottom: 1px solid var(--sinace-bdr-color);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.news-details__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-details__meta li {
  position: relative;
  display: flex;
  align-items: center;
}

.news-details__meta li+li {
  margin-left: 32px;
}

.news-details__meta li .icon {
  position: relative;
  display: inline-block;
  margin-right: 4px;
}

.news-details__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--sinace-base);
}

.news-details__meta li .text {
  position: relative;
  display: block;
}

.news-details__meta li .text p {
  font-size: 14px;
  font-weight: 400;
  color: #7b7e84;
}

.news-details__meta li>span {
  position: absolute;
  top: 0;
  left: -20px;
  font-size: 14px;
  color: #d9e1e9;
}

.news-details__title-3 {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  
}

.news-details__title-2 {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  
}

.news-details__title-1 {
  position: relative;
  display: block;
  font-size: 28px;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 18px;
  border-bottom: 2px solid var(--sinace-bdr-color);
}

.news-details__title-1::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 70px;
  background-color: var(--sinace-base);
}

.news-details__text-1 {
  font-size: 15px;
}

.news-details__text-2 {
  margin-top: 30px;
  margin-bottom: 31px;
}

.news-details__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 29px;
  margin-bottom: 31px;
}

.news-details__bottom p {
  margin: 0;
}

.news-details__tags span {
  color: var(--sinace-black);
  font-size: 20px;
  margin-right: 7px;
  font-weight: 600;
  letter-spacing: var(--sinace-letter-spacing);
}

.news-details__tags a {
  position: relative;
  color: var(--sinace-white);
  font-size: 13px;
  background-color: var(--sinace-base);
  display: inline-block;
  padding: 4px 20px 6px;
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-details__tags a:hover {
  background-color: var(--sinace-black);
  color: var(--sinace-white);
}

.news-details__tags a+a {
  margin-left: 7px;
}

.news-details__social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.news-details__social-list a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--sinace-black);
  background-color: var(--sinace-primary);
  font-size: 14px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.news-details__social-list a:hover {
  color: var(--sinace-white);
}

.news-details__social-list a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--sinace-base);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scalex(0);
  transform: scaleX(0);
  z-index: -1;
}

.news-details__social-list a:hover:after {
  opacity: 1;
  -webkit-transform: scalex(1);
  transform: scalex(1);
}

.news-details__social-list a+a {
  margin-left: 10px;
}

.news-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 54px;
}

.news-details__pagenation {
  position: relative;
  display: block;
}

.news-details__pagenation li {
  position: relative;
  float: left;
  font-size: 20px;
  color: var(--sinace-black);
  font-weight: 600;
  background-color: var(--sinace-primary);
  line-height: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 370px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 53px;
  padding-bottom: 47px;
  letter-spacing: var(--sinace-letter-spacing);
}

.news-details__pagenation li+li {
  margin-left: 30px;
}

.news-details__pagenation li:hover {
  background-color: var(--sinace-black);
  color: var(--sinace-white);
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-one__title,
.comment-form__title {
  margin: 0;
  font-size: 36px;
  margin-bottom: 52px;
  font-weight: 600;
}

.comment-one__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid var(--sinace-bdr-color);
  padding-bottom: 60px;
  margin-bottom: 60px;
  position: relative;
  align-items: center;
}

.comment-one__content {
  position: relative;
  margin-left: 45px;
}

.comment-one__content h3 {
  margin: 0;
  font-size: 20px;
  margin-bottom: 23px;
  font-weight: 600;
}

.comment-one__content p {
  font-size: 15px;
}

.comment-one__btn {
  padding: 4px 20px 6px;
  position: absolute;
  top: -11px;
  right: 0;
  font-size: 13px;
  font-weight: 400;
  color: var(--sinace-white);
}

.comment-one__image {
  position: relative;
  display: block;
  border-radius: 50%;
}

.comment-one__image img {
  border-radius: 50%;
}

.comment-form .comment-form__title {
  margin-top: -7px;
}

.comment-one__form .row {
  --bs-gutter-x: 20px;
}

.comment-form__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.comment-form__input-box input[type="text"],
.comment-form__input-box input[type="email"] {
  height: 58px;
  width: 100%;
  border: none;
  background-color: var(--sinace-primary);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: var(--sinace-gray);
  display: block;
}

.comment-form__input-box textarea {
  font-size: 14px;
  color: var(--sinace-gray);
  height: 138px;
  width: 100%;
  background-color: var(--sinace-primary);
  padding: 20px 30px 30px;
  border: none;
  outline: none;
  font-weight: 400;
  margin-bottom: 0px;
}

.comment-form__btn {
  border: none;
}

.comment-form__input-box.text-message-box {
  height: 138px;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar__single+.sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  position: relative;
  margin: 0;
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: 600;
  padding-left: 12px;
}

.sidebar__title::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  border-top: 6px solid transparent;
  border-left: 7px solid var(--sinace-base);
  border-bottom: 6px solid transparent;
}

.sidebar__search {
  position: relative;
  display: block;
}

.sidebar__search-form {
  position: relative;
}

.sidebar__search-form input[type="search"] {
  display: block;
  border: none;
  outline: none;
  background-color: var(--sinace-base);
  color: var(--sinace-white);
  font-size: 18px;
  font-weight: 500;
  padding-left: 50px;
  height: 76px;
  width: 100%;
  padding-right: 80px;
}

.sidebar__search-form ::-webkit-input-placeholder {
  color: var(--sinace-white);
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: var(--sinace-white);
  opacity: 1;
}

.sidebar__search-form ::-ms-input-placeholder {
  color: var(--sinace-white);
  opacity: 1;
}

.sidebar__search-form ::placeholder {
  color: var(--sinace-white);
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: var(--sinace-white);
}

.sidebar__search-form ::-ms-input-placeholder {
  color: var(--sinace-white);
}

.sidebar__search-form button[type="submit"] {
  background-color: transparent;
  color: var(--sinace-white);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 46px 30px 30px;
  background-color: var(--sinace-primary);
  overflow: hidden;
  z-index: 1;
}

.sidebar__post .sidebar__title {
  margin-left: 20px;
}

.sidebar__post-list {
  margin: 0;
}

.sidebar__post-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 20px 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__post-list li:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
}

.sidebar__post-list li+li {
  margin-top: 10px;
}

.sidebar__post-image {
  margin-right: 17px;
}

.sidebar__post-image>img {
  width: 70px;
}

.sidebar__post-content {
  position: relative;
  top: -3px;
}

.sidebar__post-content h3 {
  font-size: 18px;
  margin: 0;
  line-height: 26px;
}

.sidebar__post-content-meta {
  line-height: 23px;
  font-weight: 400;
  font-size: 14px;
  color: var(--sinace-gray);
  letter-spacing: normal;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__post-content-meta i {
  color: var(--sinace-base);
  font-size: 13px;
  padding-right: 4px;
}

.sidebar__post-content h3 a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
}

.sidebar__category {
  position: relative;
  display: block;
  background-color: var(--sinace-primary);
  padding: 46px 30px 33px;
  overflow: hidden;
  z-index: 1;
}

.sidebar__category .sidebar__title {
  margin-bottom: 9px;
}

.sidebar__category-list {
  margin: 0;
}

.sidebar__category-list li+li {
  margin-top: 4px;
}

.sidebar__category-list li a {
  color: var(--sinace-gray);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 7px 20px 7px;
  font-weight: 400;
}

.sidebar__category-list li a:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
  color: var(--sinace-black);
}

.sidebar__category-list li.active a {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
  color: var(--sinace-black);
}

.sidebar__category-list li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--sinace-base);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
}

.sidebar__category-list li a:hover span {
  color: var(--sinace-base);
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}

.sidebar__category-list li.active a span {
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  color: var(--sinace-base);
}

.sidebar__tags {
  position: relative;
  display: block;
  background: var(--sinace-primary);
  padding: 45px 45px 50px;
  overflow: hidden;
  z-index: 1;
}

.sidebar__tags .sidebar__title {
  margin-left: 5px;
  margin-bottom: 25px;
}

.sidebar__tags-list {
  margin-top: -10px;
}

.sidebar__tags-list a {
  font-size: 13px;
  color: var(--sinace-black);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: var(--sinace-white);
  display: inline-block;
  padding: 4px 20px 6px;
  margin-left: 6px;
  font-weight: 400;
}

.sidebar__tags-list a+a {
  margin-left: 6px;
  margin-top: 10px;
}

.sidebar__tags-list a:hover {
  color: var(--sinace-white);
  background: var(--sinace-base);
}

/*--------------------------------------------------------------
# Project Details
--------------------------------------------------------------*/
.project-details {
  position: relative;
  display: block;
  padding: 120px 0 0;
}

.project-details__img {
  position: relative;
  display: block;
}

.project-details__img img {
  width: 100%;
}

.project-details__info {
  position: relative;
  display: block;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  margin-top: -69px;
  padding: 39px 60px 49px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.project-details__info-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-details__info-list li {
  position: relative;
  display: block;
}

.project-details__info-list li:first-child::before {
  display: none;
}

.project-details__info-list li::before {
  content: "";
  position: absolute;
  top: 8px;
  left: -68px;
  height: 47px;
  width: 1px;
  background-color: rgba(var(--sinace-black-rgb), .10);
  transform: rotate(17deg);
}

.project-details__info-list li p {
  margin-bottom: 6px;
}

.project-details__info-list li h4 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
}

.project-details__social-list {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: -5px;
}

.project-details__social-list a {
  font-size: 14px;
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__social-list a:hover {
  color: var(--sinace-base);
}

.project-details__social-list a+a {
  margin-left: 27px;
}

.project-details__shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: var(--sinace-base);
}

.project-details__title-1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 27px;
}

.project-details__title-2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  margin-top: 42px;
  margin-bottom: 23px;
}

.project-details__img-and-points-box {
  position: relative;
  display: block;
  margin-top: 52px;
  margin-bottom: 51px;
}

.project-details__img-two {
  position: relative;
  display: block;
}

.project-details__img-two img {
  width: 100%;
}

.project-details__points {
  position: relative;
  display: block;
}

.project-details__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.project-details__points li+li {
  margin-top: 6px;
}

.project-details__points li .icon {
  position: relative;
  display: inline-block;
  top: 3px;
}

.project-details__points li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 9px;
  border-radius: 50%;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
}

.project-details__points li .text {
  margin-left: 20px;
}

.project-details__points li .text p {
  font-size: 16px;
  font-weight: 500;
  color: var(--sinace-black);
}

.project-details__pagination-box {
  position: relative;
  display: block;
  margin-top: 54px;
}

.project-details__pagination-single {
  position: relative;
  display: flex;
  background-color: var(--sinace-primary);
  padding: 43px 50px 45px;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__pagination-single-two {
  justify-content: flex-end;
}

.project-details__pagination-single:hover {
  background-color: var(--sinace-base);
}

.project-details__next-content {
  position: relative;
  display: block;
}

.project-details__pagination-single a {
  position: relative;
  display: block;
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__pagination-sub-title {
  font-size: 14px;
  margin: 0;
  line-height: 25px;
  font-weight: 300;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__pagination-single:hover .project-details__pagination-sub-title {
  color: var(--sinace-white);
}

.project-details__pagination-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--sinace-black);
  display: block;
  letter-spacing: var(--sinace-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__pagination-single:hover .project-details__pagination-title {
  color: var(--sinace-white);
}

.project-details__previous-content {
  position: relative;
  display: block;
  text-align: right;
}

.project-details__arrow {
  position: relative;
  display: block;
  margin-right: 20px;
}

.project-details__arrow>a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  font-size: 16px;
  color: var(--sinace-black);
  border-radius: 50%;
  background-color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-details__arrow>a:hover {
  color: var(--sinace-white);
  background-color: var(--sinace-black);
}

.project-details__previous-content {
  position: relative;
  display: block;
  text-align: right;
}


.project-details__pagination-single-two .project-details__arrow {
  position: relative;
  display: block;
  margin-left: 20px;
}

/*--------------------------------------------------------------
# Similar Project
--------------------------------------------------------------*/
.similar-project {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.similar-project .project-two__single {
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Services Details
--------------------------------------------------------------*/
.services-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.services-details__left {
  position: relative;
  display: block;
}

.services-details__category {
  position: relative;
  display: block;
  border: 2px solid var(--sinace-base);
  padding: 13px 13px 13px;
  margin-bottom: 30px;
}

.services-details__category-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  background-color: var(--sinace-base);
  color: var(--sinace-white);
  padding: 9px 35px 12px;
}

.services-details__category-list {
  margin-top: 15px;
}

.services-details__category-list li+li {
  margin-top: 4px;
}

.services-details__category-list li a {
  color: var(--sinace-gray);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 6px 20px 7px;
}

.services-details__category-list li a:hover {
  background-color: var(--sinace-primary);
  color: var(--sinace-black);
  text-shadow: 0.5px 0 0 rgb(15 33 60 / 80%);
}

.services-details__category-list li.active a {
  background-color: var(--sinace-primary);
  color: var(--sinace-black);
  text-shadow: 0.5px 0 0 rgb(15 33 60 / 80%);
}

.services-details__category-list li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--sinace-base);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
}

.services-details__category-list li a:hover span {
  color: var(--sinace-base);
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}

.services-details__category-list li.active a span {
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  color: var(--sinace-base);
}

.services-details__need-help {
  position: relative;
  display: block;
  text-align: center;
  background-color: #eef3f8;
  padding: 50px 50px 50px;
  overflow: hidden;
  z-index: 1;
}

.services-details__need-help-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .20;
  mix-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.services-details__need-help-icon {
  position: relative;
  display: inline-block;
}

.services-details__need-help-icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--sinace-black);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.services-details__need-help:hover .services-details__need-help-icon span {
  transform: scale(0.9);
}

.services-details__need-help-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 18px;
  margin-bottom: 30px;
}

.services-details__need-help-btn-box {
  position: relative;
  display: block;
}

.services-details__need-help-btn {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 30px 4px;
}

.services-details__need-help-shape-1 {
  position: absolute;
  bottom: 78px;
  left: -262px;
  z-index: -1;
}

.services-details__need-help-shape-1 img {
  width: auto;
}

.services-details__need-help-shape-2 {
  position: absolute;
  top: 42px;
  left: -100px;
  z-index: -1;
}

.services-details__need-help-shape-2 img {
  width: auto;
}

.services-details__need-help-shape-3 {
  position: absolute;
  bottom: -108px;
  right: -114px;
  z-index: -1;
}

.services-details__need-help-shape-3 img {
  width: auto;
}

.services-details__right {
  position: relative;
  display: block;
}

.services-details__img {
  position: relative;
  display: block;
}

.services-details__img img {
  width: 100%;
}

.services-details__icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--sinace-base);
}

.services-details__icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--sinace-white);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.services-details__icon:hover span {
  transform: scale(.9);
}

.services-details__title-1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  margin-top: 19px;
  margin-bottom: 21px;
}

.services-details__text-box {
  position: relative;
  display: block;
  padding: 19px 40px 21px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.services-details__text-box::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  bottom: 5px;
  width: 4px;
  border-radius: 2px;
  background-color: var(--sinace-black);
}

.services-details__text-box-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: var(--sinace-base);
}

.services-details__points {
  position: relative;
  display: block;
  margin-top: 55px;
}

.services-details__points-single {
  position: relative;
  display: flex;
  border-bottom: 4px solid #d9e1e9;
  margin-bottom: 30px;
}

.services-details__points-single .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90px;
  width: 100%;
  height: 115px;
  background-color: #eef3f8;
}

.services-details__points-single .icon span {
  position: relative;
  display: inline-block;
  font-size: 62px;
  color: var(--sinace-base);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.services-details__points-single:hover .icon span {
  transform: scale(.9);
}

.services-details__points-single .content {
  position: relative;
  display: block;
  margin-left: 30px;
  margin-top: -6px;
}

.services-details__points-single .content h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.services-details__points-single .content p {
  margin-top: 11px;
}

.services-details__benefit {
  position: relative;
  display: block;
  margin-top: 30px;
}

.services-details__benefit-img {
  position: relative;
  display: block;
  margin-right: 27px;
}

.services-details__benefit-img img {
  width: 100%;
  border-radius: 50%;
}

.services-details__benefit-content {
  position: relative;
  display: block;
  margin-top: 32px;
}

.services-details__benefit-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}

.services-details__benefit-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: var(--sinace-base);
  margin-top: 32px;
  margin-bottom: 37px;
}

.services-details__benefit-points {
  position: relative;
  display: block;
}

.services-details__benefit-points li {
  position: relative;
  display: flex;
  align-items: center;
}

.services-details__benefit-points li+li {
  margin-top: 10px;
}

.services-details__benefit-points li .icon {
  position: relative;
  display: inline-block;
}

.services-details__benefit-points li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 9px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  border-radius: 50%;
}

.services-details__benefit-points li .text {
  margin-left: 15px;
}

.services-details__benefit-points li .text p {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}

/*--------------------------------------------------------------
# Team Details
--------------------------------------------------------------*/
.team-details {
  position: relative;
  display: block;
  z-index: 1;
}

.team-details__top {
  position: relative;
  display: block;
  padding: 120px 0 100px;
  border-bottom: 1px solid var(--sinace-bdr-color);
}

.team-details__left {
  position: relative;
  display: block;
  margin-right: 20px;
}

.team-details__img {
  position: relative;
  display: block;
}

.team-details__img img {
  width: 100%;
}

.team-details__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: -11px;
}

.team-details__top-content {
  position: relative;
  display: block;
}

.team-details__top-name {
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
}

.team-details__top-sub-title {
  margin-bottom: 20px;
}

.team-details__social {
  position: relative;
  display: flex;
  align-items: center;
}

.team-details__social a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: var(--sinace-black);
  background-color: #eef3f8;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.team-details__social a:hover {
  color: var(--sinace-white);
  background-color: var(--sinace-base);
}

.team-details__social a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--sinace-base);
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}

.team-details__social a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.team-details__social a+a {
  margin-left: 10px;
}

.team-details__top-text-1 {
  font-size: 28px;
  font-weight: 300;
  line-height: 30px;
  color: var(--sinace-base);
  margin-top: 26px;
  margin-bottom: 34px;
}

.team-details__top-text-3 {
  margin-top: 30px;
  margin-bottom: 31px;
}

.team-details__bottom {
  position: relative;
  display: block;
  padding-top: 93px;
  padding-bottom: 120px;
}

.team-details__bottom-left {
  position: relative;
  display: block;
  margin-right: 50px;
  margin-top: 31px;
}

.team-details__bottom-left-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
}

.team-details__bottom-left-text {
  font-size: 18px;
  padding-top: 32px;
  font-weight: 300;
}

.team-details__bottom-right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.team-details__progress {
  position: relative;
  display: block;
}

.team-details__progress-single+.team-details__progress-single {
  margin-top: 14px;
}

.team-details__progress-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: var(--sinace-black);
  margin-bottom: 15px;
}

.team-details__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: var(--sinace-primary);
  border-radius: 6px;
}

.team-details__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 5px;
  background-color: var(--sinace-base);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.team-details__progress .bar-inner::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -3px;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: var(--sinace-black);
  border: 2px solid var(--sinace-white);
  transform: translateY(-50%);
}

.team-details__progress .count-text {
  position: absolute;
  right: -6px;
  bottom: 26px;
  color: var(--sinace-gray);
  line-height: 26px;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-details__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.team-details__progress .bar.marb-0 {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact Two
--------------------------------------------------------------*/
.contact-two {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 120px 0 120px;
  background-color: #eef3f8;
  z-index: 1;
}

.contact-two .container {
  max-width: 800px;
}

.contact-two__form-box {
  position: relative;
  display: block;
}

.contact-two__form {
  position: relative;
  display: block;
}

.contact-two__form .row {
  --bs-gutter-x: 20px;
}

.contact-two__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.contact-two__input-box input[type="text"],
.contact-two__input-box input[type="email"] {
  height: 58px;
  width: 100%;
  border: none;
  background-color: var(--sinace-white);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: var(--sinace-gray);
  display: block;
}

.contact-two__input-box textarea {
  font-size: 14px;
  color: var(--sinace-gray);
  font-weight: 400;
  height: 138px;
  width: 100%;
  background-color: var(--sinace-white);
  padding: 20px 30px 30px;
  border: none;
  outline: none;
  margin-bottom: 0px;
}

.contact-two__btn-box {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.contact-two__btn {
  border: none;
}

.contact-two__input-box.text-message-box {
  height: 138px;
}

.contact-two__shape-1 {
  position: absolute;
  top: 0;
  left: -30px;
  opacity: .30;
  z-index: -1;
}

.contact-two__shape-1 img {
  width: auto;
}

.contact-two__shape-2 {
  position: absolute;
  top: -490px;
  left: 0;
  z-index: -1;
}

.contact-two__shape-2 img {
  width: auto;
}

.contact-two__shape-3 {
  position: absolute;
  top: 84px;
  left: 0;
  z-index: -1;
}

.contact-two__shape-3 img {
  width: auto;
}

.contact-two__shape-4 {
  position: absolute;
  top: 0;
  right: -30px;
  opacity: .30;
  z-index: -1;
}

.contact-two__shape-4 img {
  width: auto;
}

.contact-two__shape-5 {
  position: absolute;
  bottom: -325px;
  right: 0;
  z-index: -1;
}

.contact-two__shape-5 img {
  width: auto;
}

/*--------------------------------------------------------------
# Careers Page
--------------------------------------------------------------*/
.careers-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.careers-page__single {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--sinace-bdr-color);
  padding-right: 49px;
  margin-bottom: 30px;
}

.careers-page__img {
  position: relative;
  display: block;
  max-width: 199px;
  width: 100%;
  z-index: 1;
}

.careers-page__img img {
  width: 100%;
}

.careers-page__tag {
  position: absolute;
  top: 30px;
  right: -65px;
  padding: 11px 20px 11px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.careers-page__tag p {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}

.careers-page__content {
  position: relative;
  display: block;
  margin-left: 30px;
}

.careers-page__meta {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 55px;
  margin-bottom: 16px;
}

.careers-page__meta li {
  position: relative;
  display: flex;
  align-items: center;
}

.careers-page__meta li:before {
  content: "";
  position: absolute;
  top: 9px;
  bottom: 9px;
  right: -15px;
  width: 1px;
  background-color: #d9e1e9;
  transform: rotate(20deg);
}

.careers-page__meta li:last-child:before {
  display: none;
}

.careers-page__meta li+li {
  margin-left: 30px;
}

.careers-page__meta li .icon {
  position: relative;
  display: flex;
  align-items: center;
}

.careers-page__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--sinace-base);
}

.careers-page__meta li .text {
  margin-left: 4px;
}

.careers-page__meta li .text p {
  font-size: 14px;
  font-weight: 400;
  color: #7b7e84;
}

.careers-page__title {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: var(--sinace-letter-spacing);
}

.careers-page__title a {
  color: var(--sinace-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.careers-page__title a:hover {
  color: var(--sinace-base);
}

.careers-page__text {
  font-size: 15px;
}

.careers-page__btn-box {
  position: relative;
  display: block;
  margin-top: 20px;
}

.careers-page__btn {
  font-size: 12px;
  font-weight: 600;
  padding: 2px 20px 2px;
}

/*--------------------------------------------------------------
# Feature Three
--------------------------------------------------------------*/
.feature-three {
  position: relative;
  display: block;
  padding: 120px 0 0;
}

.feature-three__single {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 25px;
}

.feature-three__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 122px;
  width: 122px;
  border: 1px solid var(--sinace-bdr-color);
  border-radius: 50%;
  margin: 0 auto 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}

.feature-three__single:hover .feature-three__icon {
  border: 1px solid var(--sinace-base);
}

.feature-three__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 50%;
  background-color: var(--sinace-base);
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.feature-three__single:hover .feature-three__icon:before {
  transform: scaleX(1);
}

.feature-three__icon span {
  position: relative;
  display: inline-block;
  font-size: 55px;
  color: var(--sinace-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.feature-three__single:hover .feature-three__icon span {
  transform: scale(0.9);
  color: var(--sinace-white);
}

.feature-three__title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: var(--sinace-letter-spacing);
}

/*--------------------------------------------------------------
# Faq Search
--------------------------------------------------------------*/
.faq-search {
  position: relative;
  display: block;
  padding: 35px 0 60px;
}

.faq-search .container {
  max-width: 800px;
}

.faq-search .section-title {
  margin-bottom: 41px;
}

.faq-search-box__form {
  position: relative;
  display: block;
}

.faq-search-box__form-input {
  position: relative;
  display: block;
}

.faq-search-box__form-input input[type="search"] {
  height: 84px;
  width: 100%;
  border: 1px solid var(--sinace-bdr-color);
  outline: none;
  background-color: var(--sinace-white);
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
  font-size: 18px;
  color: var(--sinace-gray);
  font-weight: 300;
  padding-left: 40px;
  padding-right: 75px;
}

.faq-search-box__form-input button[type="submit"] {
  background-color: transparent;
  color: var(--sinace-base);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 62px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
}

/*--------------------------------------------------------------
# Faq Page
--------------------------------------------------------------*/
.faq-page {
  position: relative;
  display: block;
  padding: 0 0 90px;
}

.faq-page__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Team Page
--------------------------------------------------------------*/
.team-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

/*--------------------------------------------------------------
# Testimonial Page
--------------------------------------------------------------*/
.testimonials-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.testimonial-two__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Services Page
--------------------------------------------------------------*/
.services-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.services-page__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.services-page__img-box {
  position: relative;
  display: block;
}

.services-page__img {
  position: relative;
  display: block;
}

.services-page__img img {
  width: 100%;
}

.services-page__icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.services-page__icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 67px;
  font-size: 32px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
}

.services-page__content {
  position: relative;
  display: block;
  padding: 23px 30px 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.services-page__content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6px;
  background-color: var(--sinace-base);
}

.services-page__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.services-page__title a {
  color: #222232;
}

.services-page__text {
  margin-top: 11px;
  margin-bottom: 6px;
}

.services-page__read-more {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #081528;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.services-page__hover-single {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: bottom left;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  z-index: 2;
}

.services-page__single:hover .services-page__hover-single {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.services-page__hover-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.services-page__hover-img::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6px;
  background-color: var(--sinace-base);
  z-index: 2;
}

.services-page__hover-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(8, 21, 40, .80);
  z-index: 1;
}

.services-page__hover-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 67px;
  top: 0;
  right: 0;
  background-color: var(--sinace-base);
  z-index: 2;
}

.services-page__hover-icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--sinace-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.services-page__hover-single:hover .services-page__hover-icon span {
  transform: scale(0.9);
}

.services-page__hover-content {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  z-index: 2;
}

.services-page__hover-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.services-page__hover-title a {
  color: var(--sinace-white);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-page__hover-title a:hover {
  color: var(--sinace-base);
}

.services-page__hover-text {
  margin-top: 11px;
  color: rgba(var(--sinace-white-rgb), .70);
  margin-bottom: 6px;
}

.services-page__hover-read-more {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--sinace-base);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.services-page__hover-read-more:hover {
  color: var(--sinace-white);
}

/*--------------------------------------------------------------
# Portfolio Page
--------------------------------------------------------------*/
.portfolio-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.portfolio-page .project-two__single {
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# About Four
--------------------------------------------------------------*/
.about-four {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.about-four__left {
  position: relative;
  display: block;
  margin-right: 110px;
}

.about-four__img-box {
  position: relative;
  display: block;
}

.about-four__shape-1 {
  position: absolute;
  left: -23px;
  top: 40px;
  border-style: solid;
  border-width: 6px;
  border-color: rgb(255, 131, 0);
  background-color: rgba(24, 41, 57, 0);
  width: 538px;
  height: 429px;
}

.about-four__img {
  position: relative;
  display: block;
  z-index: 1;
}

.about-four__img img {
  width: 100%;
}

.about-four__img-two {
  position: absolute;
  top: 0;
  right: -110px;
  z-index: 2;
}

.about-four__img-two img {
  width: auto;
}

.about-four__right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.about-four__right .section-title {
  margin-bottom: 31px;
}

.about-four__points {
  position: relative;
  display: block;
  margin-top: 31px;
  margin-bottom: 45px;
}

.about-four__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.about-four__points li+li {
  margin-top: 9px;
}

.about-four__points li .icon {
  position: relative;
  display: inline-block;
}

.about-four__points li .icon span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  font-size: 9px;
  color: var(--sinace-white);
  background-color: var(--sinace-base);
  border-radius: 50%;
}

.about-four__points li .text {
  margin-left: 22px;
}

.about-four__points li .text p {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--sinace-black);
}

.about-four__btn-box {
  position: relative;
  display: inline-block;
}

.about-four__shape-2 {
  position: absolute;
  bottom: 5px;
  right: -145px;
}

.about-four__shape-2 img {
  width: auto;
}

/*--------------------------------------------------------------
# About Page Team
--------------------------------------------------------------*/
.about-page-team {
  padding-top: 120px;
}

/*--------------------------------------------------------------
# About Page Testimonial
--------------------------------------------------------------*/
.about-page-testimonial {
  padding-bottom: 90px;
}















/*--------------------------------------------------------------
# Contact seven
--------------------------------------------------------------*/
/* ............................................................................ */
/* Medium screen  */
@media (min-width: 375px) and (max-width: 667px) {
  .bg-width {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {


  .about-one__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .about-one__right {
    max-width: 600px;
    margin: 141px auto 0;
  }

  .services-one__left .section-title__title {
    font-size: 34px;
    line-height: 44px;
  }

  .grow-business__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .grow-business__right {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .testimonial-one__shape-1 {
    border-left: 145px solid transparent;
    border-right: 145px solid transparent;
  }

  .testimonial-one {
    padding: 120px 0 104px;
  }

  .project-one__content {
    margin-right: 50px;
    padding: 33px 20px 47px;
  }

  .project-one__content h3 {
    font-size: 19px;
  }

  .team-one__left .section-title__title {
    font-size: 40px;
    line-height: 50px;
  }

  .team-one__hover-content {
    right: 20px;
    padding: 25px 20px 30px;
  }

  .team-one__hover-arrow-box {
    right: 20px;
  }

  .counter-one__left .section-title__title {
    font-size: 37px;
    line-height: 47px;
  }

  .counter-one__left .section-title__title2 {
    font-size: 37px;
    line-height: 47px;
    margin-top: 50px;
  }

  .counter-one__right {
    margin-left: 0;
  }

  .counter-one__call-box {
    position: relative;
    margin-top: 20px;
    margin-left: 27px;
  }

  .contact-one__left {
    margin-right: 0;
  }

  .contact-one__right .section-title__title {
    font-size: 38px;
  }

  .news-one__content {
    padding: 22px 20px 34px;
  }

  .news-one__title {
    font-size: 21px;
  }


  .news-one__hover-content {
    padding: 18px 20px 23px;
  }

  .news-one__hover-title {
    font-size: 21px;
  }

  .news-one__hover-btn-box a {
    padding: 15px 20px 16px;
  }


  .footer-widget__about {
    margin-right: 0;
  }

  .footer-widget__link {
    margin-left: 0;
  }

  .footer-widget__portfolio {
    margin-right: 0;
    margin-top: 32px;
  }

  .footer-widget__newsletter {
    margin-top: 32px;
  }

  .feature-one__hover-content {
    padding: 15px 20px 15px;
  }

  .feature-one__hover-text {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .about-two__left {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .about-two__right {
    padding-top: 60px;
    max-width: 600px;
    margin: 0 auto 0;
  }

  .about-two {
    padding: 0 0 120px;
  }

  .about-two__shape-1 {
    position: absolute;
    left: 149px;
    bottom: 102px;
  }

  .faq-one__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .faq-one__right {
    max-width: 600px;
    margin: 50px auto 0;
  }

  .testimonial-two__single {
    padding-right: 100px;
  }

  .project-two__left {
    margin-right: 0;
    margin-bottom: 50px;
    max-width: 600px;
  }

  .project-two__right {
    margin-right: 0;
  }

  .project-two__bg {
    display: none;
  }

  .project-two__inner::before {
    right: 0;
  }

  .feature-two__content {
    margin-left: 15px;
  }

  .feature-two__content h3 {
    font-size: 17px;
  }

  .feature-two__content p br {
    display: none;
  }

  .about-three__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .about-three__img-2 {
    left: 0;
  }

  .about-three__shape-2 {
    display: none;
  }

  .about-three__right {
    max-width: 600px;
    margin: 60px auto 0;
  }

  .cta-one__img {
    position: relative;
  }

  .cta-one__inner {
    padding-left: 60px;
    flex-direction: column;
    align-items: baseline;
  }

  .cta-one__title {
    margin-top: 13px;
    margin-bottom: 17px;
  }

  .testimonial-three__left {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    padding: 88px 50px 100px;
  }

  .testimonial-three__right {
    padding-top: 60px;
    max-width: 600px;
    margin: 0 auto 0;
  }

  .testimonial-three__bg {
    display: none;
  }

  .testimonial-three {
    padding: 0 0 120px;
  }

  .together-one__left {
    max-width: 600px;
    margin: 0 auto 50px;
  }

  .together-one__right {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .expectation-one__points li+li {
    margin-left: 80px;
  }

  .expectation-one__points li::before {
    right: -40px;
  }

  .news-two__title {
    position: absolute;
    left: 20px;
    right: 20px;
    font-size: 18px;
    line-height: 30px;
  }

  .brand-one__title:before,
  .brand-one__title:after {
    display: none;
  }

  .about-four__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .about-four__right {
    max-width: 600px;
    margin: 60px auto 0;
  }

  .careers-page__single {
    padding-right: 20px;
  }

  .careers-page__title {
    font-size: 18px;
  }

  .careers-page__content {
    margin-left: 20px;
  }

  .careers-page__meta {
    margin-left: 0;
  }

  .careers-page__tag {
    right: 0;
  }

  .contact-page__right {
    margin-left: 0;
  }

  .contact-page__right .section-title__title {
    font-size: 38px;
  }

  .news-details__pagenation li+li {
    margin-left: 0;
    margin-top: 30px;
  }

  .team-details__right {
    margin-left: 0;
  }

  .feature-two__shape-1 {
    display: none;
  }








}


















/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-one__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .about-one__right {
    max-width: 600px;
    margin: 141px auto 0;
  }

  .about-one__img-2 {
    right: 0;
  }

  .grow-business__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .grow-business__right {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .video-one__title {
    font-size: 50px;
    line-height: 60px;
  }

  .testimonial-one__shape-1 {
    border-left: 165px solid transparent;
    border-right: 165px solid transparent;
  }

  .testimonial-one__left {
    margin-bottom: 60px;
  }

  .project-one__content {
    margin-right: 50px;
    padding: 33px 20px 47px;
  }

  .counter-one__right {
    margin-left: 0;
    margin-top: 50px;
  }

  .counter-one__call-box {
    position: relative;
    margin-top: 20px;
    margin-left: 27px;
  }

  .contact-one__left {
    margin-right: 0;
    margin-bottom: 60px;
  }


  .footer-widget__about {
    margin-right: 0;
  }

  .footer-widget__link {
    margin-left: 0;
  }

  .footer-widget__portfolio {
    margin-right: 0;
    margin-top: 32px;
  }

  .footer-widget__newsletter {
    margin-top: 32px;
  }

  .feature-one__hover-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .about-two__left {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .about-two__right {
    padding-top: 60px;
    max-width: 600px;
    margin: 0 auto 0;
  }

  .about-two {
    padding: 0 0 120px;
  }

  .about-two__shape-1 {
    left: 145px;
    bottom: 105px;
  }

  .faq-one__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .faq-one__right {
    max-width: 600px;
    margin: 50px auto 0;
  }

  .testimonial-two__single {
    padding: 40px 20px 33px;
    padding-right: 20px;
  }

  .project-two__left {
    margin-right: 0;
    margin-bottom: 50px;
    max-width: 600px;
  }

  .project-two__right {
    margin-right: 0;
  }

  .project-two__bg {
    display: none;
  }

  .project-two__inner::before {
    right: 0;
  }

  .get-free__shape-2,
  .get-free__shape-3,
  .get-free__shape-4,
  .get-free__shape-5 {
    display: none;
  }

  .get-free__bg-two {
    display: none;
  }

  .get-free__bg-box {
    width: 100%;
  }

  .get-free__inner {
    flex-direction: column;
    align-items: baseline;
  }

  .get-free__right {
    margin-left: 0;
    margin-top: 77px;
  }

  .get-free {
    padding: 100px 0 140px;
  }

  .about-three__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .about-three__img-2 {
    left: 0;
  }

  .about-three__shape-2 {
    display: none;
  }

  .about-three__right {
    max-width: 600px;
    margin: 60px auto 0;
  }

  .cta-one__img {
    position: relative;
  }

  .cta-one__inner {
    padding-left: 60px;
    flex-direction: column;
    align-items: baseline;
  }

  .cta-one__title {
    margin-top: 13px;
    margin-bottom: 17px;
  }

  .testimonial-three__left {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    padding: 88px 50px 100px;
  }

  .testimonial-three__right {
    padding-top: 60px;
    max-width: 600px;
    margin: 0 auto 0;
  }

  .testimonial-three__bg {
    display: none;
  }

  .testimonial-three {
    padding: 0 0 120px;
  }

  .together-one__left {
    max-width: 600px;
    margin: 0 auto 50px;
  }

  .together-one__right {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .expectation-one__points li+li {
    margin-left: 80px;
  }

  .expectation-one__points li::before {
    right: -40px;
  }

  .brand-one__title:before,
  .brand-one__title:after {
    display: none;
  }

  .about-four__left {
    max-width: 600px;
    margin: 0 auto 0;
  }

  .about-four__right {
    max-width: 600px;
    margin: 60px auto 0;
  }

  .contact-page__right {
    margin-left: 0;
    margin-top: 60px;
  }

  .news-details__pagenation li+li {
    margin-left: 0;
    margin-top: 30px;
  }

  .project-details__info {
    padding: 39px 20px 49px;
  }

  .project-details__info-list li::before {
    left: -22px;
  }

  .project-details__img-two {
    margin-bottom: 30px;
  }

  .project-details__pagination-single-two {
    margin-top: 30px;
  }

  .services-details__left {
    margin-bottom: 60px;
  }

  .team-details__left {
    margin-right: 0;
    margin-bottom: 60px;
  }

  .team-details__right {
    margin-left: 0;
  }

  .team-details__bottom-right {
    margin-left: 0;
    margin-top: 40px;
  }

  .feature-two__shape-1 {
    display: none;
  }


}






























/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .section-title__title {
    font-size: 35px;
    line-height: 45px;
  }

  .section-title__title br {
    display: none;
  }

  .about-one__left {
    margin-right: 0;
  }

  .about-one__img-2 {
    display: none;
  }

  .about-one__right {
    margin-left: 0;
    margin-top: 60px;
  }

  .about-one__points-box {
    flex-direction: column;
    align-items: baseline;
  }

  .about-one__points-list+.about-one__points-list {
    margin-left: 0;
    margin-top: 8px;
  }

  .about-one__shape-3 {
    display: none;
  }

  .grow-business__left {
    margin-right: 0;
  }

  .grow-business__right {
    margin-left: 0;
    padding: 100px 15px 91px;
  }

  .grow-business__right-points-text br {
    display: none;
  }

  .video-one__title {
    font-size: 40px;
    line-height: 50px;
  }

  .video-one__title br {
    display: none;
  }

  .testimonial-one__left {
    margin-bottom: 60px;
  }

  .testimonial-one__shape-1 {
    display: none;
  }

  .project-one__content {
    margin-right: 50px;
    padding: 33px 20px 47px;
  }

  .project-one__content h3 {
    font-size: 19px;
  }

  .team-one__hover-content {
    right: 20px;
    padding: 25px 20px 30px;
  }

  .team-one__hover-arrow-box {
    right: 20px;
  }

  .counter-one__right {
    margin-left: 0;
    margin-top: 50px;
  }

  .counter-one__bg {
    display: none;
  }

  .motto-one__bg {
    display: none;
  }


  .counter-one__count-box li {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }

  .counter-one__call-box {
    position: relative;
    margin-top: 20px;
    margin-left: 27px;
  }

  .counter-one__bottom-inner {
    padding: 31px 15px 34px;
    padding-right: 15px;
  }

  .contact-one__left {
    padding: 50px 20px 50px;
    margin-right: 0;
    margin-bottom: 60px;
  }

  .contact-one__points li .text {
    margin-left: 15px;
  }

  .contact-one__points li .text h3 {
    font-size: 18px;
  }

  .news-one__content {
    padding: 22px 20px 34px;
  }

  .news-one__title {
    font-size: 21px;
  }


  .news-one__hover-content {
    padding: 18px 20px 23px;
  }

  .news-one__hover-title {
    font-size: 21px;
  }

  .news-one__hover-btn-box a {
    padding: 15px 20px 16px;
  }

  .footer-widget__about {
    margin-right: 0;
  }

  .footer-widget__link {
    margin-left: 0;
    margin-top: 42px;
    margin-bottom: 32px;
  }

  .footer-widget__portfolio {
    margin-right: 0;
    margin-top: 35px;
  }

  .feature-one__hover-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .feature-one__hover-content {
    padding: 15px 20px 15px;
  }

  .feature-one__hover-text {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .about-two__left {
    margin-right: 0;
    margin-left: 0;
  }

  .about-two__right {
    margin-left: 0;
    padding-top: 60px;
  }

  .about-two__successful-project {
    display: none;
  }

  .about-two__shape-1 {
    display: none;
  }

  .about-two__points {
    flex-direction: column;
    align-items: baseline;
  }

  .about-two__points li+li {
    margin-left: 0;
    margin-top: 20px;
  }

  .about-two__points li::before {
    display: none;
  }

  .about-two__btn-and-founder {
    flex-direction: column;
    align-items: baseline;
  }

  .about-two__shape-2 {
    position: relative;
    top: 0;
    left: 0;
  }

  .about-two__founder {
    margin-top: 20px;
  }

  .about-two {
    padding: 0 0 120px;
  }

  .faq-one__experience-and-points-box {
    flex-direction: column;
  }

  .faq-one__right {
    margin-top: 50px;
  }

  .testimonial-two__single {
    padding: 40px 20px 33px;
    padding-right: 20px;
  }

  .project-two__left {
    margin-right: 0;
    margin-bottom: 50px;
  }

  .project-two__right {
    margin-right: 0;
  }

  .project-two__bg {
    display: none;
  }

  .project-two__inner::before {
    right: -100000px;
  }

  .project-two__completed-box {
    flex-direction: column;
    align-items: baseline;
  }

  .project-two__completed-content {
    margin-left: 0;
    padding-left: 0;
    margin-top: 20px;
  }

  .project-two__completed-content::before {
    display: none;
  }

  .get-free__shape-2,
  .get-free__shape-3,
  .get-free__shape-4,
  .get-free__shape-5 {
    display: none;
  }

  .get-free__bg-two {
    display: none;
  }

  .get-free__bg-box {
    width: 100%;
  }

  .get-free__inner {
    flex-direction: column;
    align-items: baseline;
  }

  .get-free__right {
    margin-left: 0;
    margin-top: 77px;
  }

  .get-free {
    padding: 100px 0 140px;
  }

  .feature-two__content {
    margin-left: 15px;
  }

  .feature-two__content h3 {
    font-size: 17px;
  }

  .feature-two__content p br {
    display: none;
  }

  .about-three__left {
    margin-left: 0;
  }

  .about-three__img-2 {
    display: none;
  }

  .about-three__shape-1,
  .about-three__shape-2 {
    display: none;
  }

  .about-three__right {
    margin-left: 0;
    margin-top: 60px;
  }

  .about-three__person-and-trusted {
    margin-top: 0;
    flex-direction: column;
    align-items: baseline;
  }

  .about-three__trusted {
    margin-left: 0;
    margin-top: 40px;
  }

  .about-three__person {
    margin-top: 34px;
  }

  .about-three__person::before {
    display: none;
  }

  .about-three__text-two::before {
    display: none;
  }

  .about-three {
    padding: 120px 0 120px;
  }

  .cta-one__img {
    position: relative;
    max-width: 320px;
  }

  .cta-one__img img {
    width: 100%;
  }

  .cta-one__inner {
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: column;
    align-items: baseline;
  }

  .cta-one__title {
    margin-top: 13px;
    margin-bottom: 17px;
  }

  .cta-one__title h3 br {
    display: none;
  }

  .testimonial-three__left {
    margin-left: 0;
    margin-right: 0;
    padding: 88px 20px 100px;
  }

  .testimonial-three__text {
    font-size: 20px;
    line-height: 30px;
  }

  .testimonial-three__text br {
    display: none;
  }

  .testimonial-three__right {
    padding-top: 60px;
  }

  .testimonial-three__bg {
    display: none;
  }

  .testimonial-three {
    padding: 0 0 120px;
  }

  .together-one__left {
    margin-bottom: 50px;
  }

  .together-one__img {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .together-one__img-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .expectation-one__points {
    justify-content: inherit;
    flex-direction: column;
    align-items: baseline;
    padding: 80px 0 70px;
  }

  .expectation-one__points li+li {
    margin-left: 0;
    margin-top: 50px;
  }

  .expectation-one__points li {
    padding-top: 0;
    padding-bottom: 0;
  }

  .expectation-one__points li::before {
    display: none;
  }

  .news-two__title {
    position: absolute;
    left: 20px;
    right: 20px;
  }

  .news-two__title br {
    display: none;
  }

  .brand-one__title:before,
  .brand-one__title:after {
    display: none;
  }

  .about-four__left {
    margin-right: 0;
  }

  .about-four__img-two {
    display: none;
  }

  .about-four__shape-1 {
    display: none;
  }

  .about-four__right {
    margin-left: 0;
    margin-top: 60px;
  }

  .careers-page__single {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    align-items: baseline;
  }

  .careers-page__title {
    font-size: 18px;
  }

  .careers-page__content {
    margin-left: 0px;
    margin-top: 20px;
  }

  .careers-page__meta {
    margin-left: 0;
  }

  .careers-page__tag {
    right: 0;
  }

  .contact-page__form-box {
    padding: 50px 20px 50px;
  }

  .contact-page__right {
    margin-left: 0;
    margin-top: 60px;
  }

  .contact-page__points li .text {
    margin-left: 20px;
  }

  .contact-page__points li .text h3 {
    font-size: 17px;
  }

  .news-details__content {
    padding: 22px 22px 35px;
  }

  .news-details__bottom {
    flex-direction: column;
  }

  .news-details__social-list {
    margin-top: 20px;
  }

  .news-details__pagenation li+li {
    margin-left: 0;
    margin-top: 30px;
  }

  .news-details__pagenation li {
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .comment-one__single {
    flex-direction: column;
    align-items: baseline;
  }

  .comment-one__content {
    margin-left: 0;
    margin-top: 20px;
  }

  .project-details__info {
    padding: 39px 20px 49px;
  }

  .project-details__info-list {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .project-details__info-list li::before {
    display: none;
  }

  .project-details__info-list li+li {
    margin-top: 20px;
  }

  .project-details__img-two {
    margin-bottom: 30px;
  }

  .project-details__pagination-single-two {
    margin-top: 30px;
  }

  .project-details__pagination-single {
    padding: 43px 20px 45px;
  }

  .team-details__left {
    margin-right: 0;
    margin-bottom: 60px;
  }

  .team-details__right {
    margin-left: 0;
  }

  .team-details__bottom-right {
    margin-left: 0;
    margin-top: 40px;
  }

  .contact-two__shape-1,
  .contact-two__shape-2,
  .contact-two__shape-3,
  .contact-two__shape-4,
  .contact-two__shape-5 {
    display: none;
  }

  .feature-two__shape-1 {
    display: none;
  }
















}










@media only screen and (min-width: 1200px) and (max-width: 1890px) {
  .feature-two__shape-1 {
    display: none;
  }
}



@media only screen and (min-width: 1500px) and (max-width: 1800px) {
  .project-one__content {
    margin-right: 150px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .project-one__content {
    margin-right: 100px;
    padding: 33px 25px 47px;
  }


}



@media only screen and (min-width: 1400px) and (max-width: 1750px) {
  .testimonial-three__left {
    margin-left: 0;
    margin-right: -200px;
  }

  .testimonial-three__right {
    margin-left: 200px;
  }

  .testimonial-three__text br {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-three__left {
    margin-left: 0;
    margin-right: -150px;
    padding: 88px 20px 100px;
  }

  .testimonial-three__right {
    margin-left: 150px;
  }

  .testimonial-three__text br {
    display: none;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1680px) {}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {}

















/*--------------------------------------------------------------
# Slider All Responsice Css
--------------------------------------------------------------*/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-slider-two .owl-theme .owl-nav {
    align-items: flex-end;
    padding: 0 50px;
  }

  .main-slider-two__rounded-text {
    right: 100px;
  }


  .main-slider-three .owl-theme .owl-nav {
    justify-content: inherit;
    flex-direction: column;
    align-items: flex-end;
  }

  .main-slider-three .owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
    margin-top: 10px;
  }
}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-slider-two .owl-theme .owl-nav {
    display: none;
  }

  .main-slider-two__rounded-text {
    top: 258px;
  }

  .main-slider-three .owl-theme .owl-nav {
    justify-content: inherit;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-slider-three .owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
    margin-top: 10px;
  }
}



@media only screen and (max-width: 767px) {
  .main-slider__sub-title {
    padding: 7px 28px 7px;
  }

  .main-slider__title {
    font-size: 38px;
    line-height: 48px;
  }

  .main-slider .owl-theme .owl-nav {
    display: none;
  }




  .main-slider-two .owl-theme .owl-nav {
    display: none;
  }

  .main-slider-two__rounded-text {
    display: none;
  }

  .main-slider-two__title {
    font-size: 37px;
    line-height: 47px;
  }

  .main-slider-two__text br {
    display: none;
  }







  .main-slider-three .owl-theme .owl-nav {
    display: none;
  }

  .main-slider-three__title {
    font-size: 45px;
    line-height: 55px;
  }

  .main-slider-three__text {
    position: relative;
    left: 0;
    bottom: 0;
    margin-top: 20px;
  }

  .main-slider-three__shape-2 {
    display: none;
  }



}



@media only screen and (min-width: 1200px) and (max-width: 1450px) {
  .main-slider-two .owl-theme .owl-nav {
    align-items: flex-end;
    padding: 0 50px;
  }

  .main-slider-two__rounded-text {
    right: 100px;
  }

  .main-slider-three .owl-theme .owl-nav {
    justify-content: inherit;
    flex-direction: column;
    align-items: flex-end;
  }

  .main-slider-three .owl-theme .owl-nav [class*=owl-]+[class*=owl-] {
    margin-top: 10px;
  }
}





/*--------------------------------------------------------------
# Main Menu All Responsice Css
--------------------------------------------------------------*/

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu__top {
    display: none;
  }

  .main-menu__bottom {
    padding-left: 250px;
    padding-right: 250px;
  }

  .main-menu__bottom-inner {
    padding: 0 30px;
    padding-right: 30px;
  }

  .main-menu__btn-box {
    display: none;
  }





  .main-menu-two__top {
    display: none;
  }

  .main-menu-two__btn-box {
    display: none;
  }




  .main-menu-three__top {
    display: none;
  }

  .main-menu-three__main-menu-box-right {
    margin-left: 100px;
  }

  .main-menu-three__btn-box {
    display: none;
  }

  .main-menu-three__call {
    margin-left: 0;
  }


  .main-menu-three .mobile-nav__toggler {
    color: var(--sinace-base);
  }

  .main-menu-three .mobile-nav__toggler:hover {
    color: var(--sinace-black);
  }




















}




@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-menu__top {
    display: none;
  }

  .main-menu__bottom {
    padding-left: 250px;
    padding-right: 250px;
  }

  .main-menu__bottom-inner {
    padding: 0 30px;
    padding-right: 30px;
  }

  .main-menu__btn-box {
    display: none;
  }








  .main-menu-two__top {
    display: none;
  }

  .main-menu-two__btn-box {
    display: none;
  }

  .main-menu-two__right {
    display: none;
  }

  .main-menu-two__main-menu-box:before {
    display: none;
  }

  .main-menu-two__main-menu-box {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
    flex: 1;
  }

  .main-menu-two__main-menu-box-right {
    margin-left: 0;
  }








  .main-menu-three__top {
    display: none;
  }

  .main-menu-three__bottom {
    padding-left: 250px;
  }

  .main-menu-three__right {
    padding-right: 30px;
  }

  .main-menu-three__logo {
    left: 30px;
  }

  .main-menu-three__main-menu-box-right {
    margin-left: 100px;
  }

  .main-menu-three__btn-box {
    display: none;
  }

  .main-menu-three__call {
    margin-left: 0;
  }


  .main-menu-three .mobile-nav__toggler {
    color: var(--sinace-base);
  }

  .main-menu-three .mobile-nav__toggler:hover {
    color: var(--sinace-black);
  }










}


@media (max-width: 767px) {

  .main-menu__call {
    display: none;
  }

  .main-menu__top {
    display: none;
  }

  .main-menu__bottom {
    padding-left: 0;
    padding-right: 0;
  }

  .main-menu__logo {
    position: relative;
    left: 0;
    justify-content: center;
    padding: 12.5px 0px;
  }

  .main-menu__btn-box {
    display: none;
  }

  .main-menu__bottom-inner {
    padding: 0 30px;
    padding-right: 30px;
  }







  .main-menu-two__top {
    display: none;
  }

  .main-menu-two__bottom {
    padding-left: 0;
    padding-right: 0;
  }

  .main-menu-two__logo {
    position: relative;
    left: 0;
    justify-content: center;
    padding-top: 37px;
  }

  .main-menu-two__right {
    display: none;
  }

  .main-menu-two__btn-box {
    display: none;
  }

  .main-menu-two__main-menu-box:before {
    display: none;
  }

  .main-menu-two__main-menu-box-right {
    margin-left: 0;
  }

  .main-menu-two__main-menu-box {
    padding-left: 30px;
    padding-right: 30px;
    z-index: 1;
    justify-content: space-between;
    flex: 1;
  }









  .main-menu-three__top {
    display: none;
  }

  .main-menu-three__right {
    display: none;
  }

  .main-menu-three__main-menu-box-right {
    display: none;
  }

  .main-menu-three__bottom {
    padding-left: 0;
  }

  .main-menu-three__logo {
    position: relative;
    left: 0;
  }

  .main-menu-three__wrapper-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }

  .main-menu-three__main-menu-box {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 29px;
    padding-bottom: 30px;
    border-left: 0;
  }








  .main-menu-three .mobile-nav__toggler {
    color: var(--sinace-base);
  }

  .main-menu-three .mobile-nav__toggler:hover {
    color: var(--sinace-black);
  }













}




@media only screen and (min-width: 1400px) and (max-width: 1680px) {
  .main-menu__top {
    padding-left: 250px;
    padding-right: 250px;
  }

  .main-menu__bottom {
    padding-left: 250px;
    padding-right: 250px;
  }

  .main-menu__btn-box {
    display: none;
  }

}


@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu__top {
    padding-left: 200px;
    padding-right: 200px;
  }

  .main-menu__bottom {
    padding-left: 200px;
    padding-right: 200px;
  }

  .main-menu__btn-box {
    display: none;
  }

  .main-menu__top-menu {
    display: none;
  }

  .main-menu__logo {
    left: 15px;
  }

  .main-menu__call {
    right: 15px;
  }









  .main-menu-two__main-menu-box-right {
    margin-left: 40px;
  }

  .main-menu-two__btn-box {
    display: none;
  }

  .main-menu-two__bottom {
    padding-left: 180px;
    padding-right: 20px;
  }

  .main-menu-two__top {
    padding-left: 185px;
  }

  .main-menu-two__top-inner {
    padding: 10.5px 20px;
  }

  .main-menu-two__logo {
    left: 15px;
  }

  .main-menu-two__main-menu-box {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-menu-two__search-box {
    display: none;
  }








  .main-menu-three__top-menu {
    display: none;
  }

  .main-menu-three__top-inner {
    padding: 10.5px 30px;
    padding-right: 30px;
  }

  .main-menu-three__btn-box {
    display: none;
  }

  .main-menu-three__main-menu-box {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-menu-three__main-menu-box-right {
    display: none;
  }

  .main-menu-three__call {
    margin-left: 0;
  }

  .main-menu-three__right {
    padding-right: 30px;
  }

  .main-menu-three__bottom {
    padding-left: 186px;
  }

  .main-menu-three__top {
    padding-left: 186px;
  }

  .main-menu-three__logo {
    left: 20px;
  }




}



@media only screen and (min-width: 1600px) and (max-width: 1765px) {
  .main-menu-two__btn-box {
    display: none;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .main-menu-two__main-menu-box-right {
    margin-left: 40px;
  }

  .main-menu-two__btn-box {
    display: none;
  }

  .main-menu-two__bottom {
    padding-left: 250px;
    padding-right: 40px;
  }

  .main-menu-two__top {
    padding-left: 250px;
  }

  .main-menu-two__top-inner {
    padding: 10.5px 40px;
  }

  .main-menu-two__logo {
    left: 50px;
  }

  .main-menu-two__main-menu-box {
    padding-left: 40px;
    padding-right: 40px;
  }



  .main-menu-three__btn-box {
    display: none;
  }

  .main-menu-three__main-menu-box-right {
    margin-left: 40px;
  }

  .main-menu-three__call {
    margin-left: 0;
  }

  .main-menu-three__bottom {
    padding-left: 250px;
  }

  .main-menu-three__top {
    padding-left: 250px;
  }

  .main-menu-three__logo {
    left: 50px;
  }

  .main-menu-three__right {
    padding-right: 50px;
  }

  .main-menu-three__top-inner {
    padding-right: 50px;
  }

}



@media only screen and (min-width: 1600px) and (max-width: 1850px) {
  .main-menu-three__btn-box {
    display: none;
  }

  .main-menu-three__call {
    margin-left: 0;
  }

  .main-menu-three__main-menu-box-right {
    margin-left: 150px;
  }
}

.barprogress {
  width: 86%;
}

.page_wrapper{
  overflow: hidden;
}

.swiper-slide-active .main-slider__title, 
.swiper-slide-active .main-slider__btn-box, 
.swiper-slide-active .main-slider__sub-title {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.swiper-slide-active .main-slider-two__title,
.swiper-slide-active .main-slider-two__btn-box,
.swiper-slide-active .main-slider-two__text,
.swiper-slide-active .main-slider-two__rounded-text {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.swiper-slide-active .main-slider-three__title-box,
.swiper-slide-active .main-slider-three__btn-box {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}
